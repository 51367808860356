<template>
  <div class="view-login">
    <div class="header-box flex-c">
      <div class="logo-box flex-c">
        <div class="logo">
          <img class="w100" src="@/assets/images/pc/mb-logo.png" alt="" srcset="" />
        </div>
      </div>
    </div>
    <div class="center-box">
      <div class="input-item">
        <div class="title">Email address</div>
        <div class="input-box">
          <el-input v-model="loginFromData.account" placeholder="input"></el-input>
        </div>
      </div>
      <div class="input-item">
        <div class="title">Password</div>
        <div class="input-box">
          <el-input type="password" v-model="loginFromData.password" placeholder="input"></el-input>
        </div>
      </div>
      <div class="bottom-tip flex-s point">
        <div @click="remChecked = !remChecked" class="img">
          <div v-show="remChecked" class="in-img"></div>
        </div>
        <div @click="remChecked = !remChecked" class="remember">
          Remember me
        </div>
        <div @click="forgotOnClick" class="forgot point">
          Forgot Password？
        </div>
      </div>
      <div class="rsg-btn flex-c point" @click="userLoginOnClick">LOGIN</div>
      <div class="rsg-btn flex-c point" @click="$router.push('/Register')">
        REGISTER
      </div>
      <!-- <div class="rsg-btn flex-c point" @click="loginOverlayShow = false">
        VISITOR BROWSING
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import {

  userLoginApi,

} from "@/network/api";

import { throttle } from "lodash";
import { encode, decode } from "js-base64";
export default {
  created() {
    this.init();
  },
  methods: {
    ...mapMutations(["setShowTipPopup", 'setToken']),
    ...mapActions(['setTokenSync']),
    init() {
      if (this.isLogin) {
        this.$router.push('/');
      }
      const remUser = localStorage.getItem("rsmNo");
      if (remUser) {
        const parseRemUserArray = remUser.split("&");
        if (parseRemUserArray.length > 1) {
          this.loginFromData.account = decode(parseRemUserArray[0]);
          this.loginFromData.password = decode(parseRemUserArray[1]);
          this.remChecked = true;
        }
      }
    },
    forgotOnClick: throttle(function () {
      this.$message.warning("Please contact customer service");
    }, 3000),
    async userLoginOnClick() {
      let flag = true;
      Object.keys(this.loginFromData).forEach((key) => {
        if (!this.loginFromData[key]) {
          if (flag) {
            this.$message.error("Please fill in the complete information");
          }
          flag = false;
        }
      });
      if (!flag) {
        return false;
      }

      const res = await userLoginApi(this.loginFromData);
      if (this.remChecked) {
        const user = encode(`${this.loginFromData.account}`);
        const pass = encode(`${this.loginFromData.password}`);
        localStorage.setItem("rsmNo", `${user}&${pass}`);
      } else {
        localStorage.setItem("rsmNo", "");
      }
      // this.setToken(res?.data?.userinfo?.token);
      await this.setTokenSync(res?.data?.userinfo?.token);
      this.$message.success(res.msg);
      this.$router.push("/");
    },


  },
  data() {
    return {
      remChecked: false,
      loginOverlayShow: false,
      loginFromData: {
        account: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapState([
      "isMinDevice",
    ]),
    ...mapGetters(["isLogin"])
  },
};
</script>


<style lang="scss">
.view-login {
  width: 100%;
  min-height: 100vh;
  .header-box {
    width: 100%;
    height: 14.4393rem;
    @include pcurl("mb-login-icon1.png");
    margin-top: -1.0753rem;
    .logo-box {
      .logo {
        width: 13.0616rem;
      }
    }
  }
  .center-box {
    padding: 0 1.5873rem;
    margin-top: -2.9698rem;
    .input-item {
      margin-bottom: 0.12rem;

      .title {
        font-size: 0.7168rem;
        margin-bottom: 0.3072rem;
        color: rgba(147, 147, 147, 1);
      }
      .input-box {
        width: 100%;
        height: 1.6385rem;
        margin-bottom: 0.6144rem;
        .send-btn {
          //   width: 2.04rem;
          padding: 0.3584rem 0.5632rem;
          //   height: 1.6385rem;
          background: rgba(0, 56, 121, 1);
          border-radius: 0.04rem;
          font-size: 0.7168rem;
          color: #fff;
          flex-shrink: 0;
        }
      }
    }

    .bottom-tip {
      margin-bottom: 0.28rem;

      .img {
        width: 0.6144rem;
        height: 0.6144rem;
        border: 1px solid #e5e5e5;
        margin-right: 0.13rem;
        padding: 0.07rem;
        box-sizing: border-box;
        .in-img {
          width: 100%;
          height: 100%;
          background: rgba(136, 136, 136, 1);
        }
      }

      .remember {
        font-size: 0.7168rem;
        color: #888;
      }

      .forgot {
        font-size: 0.7168rem;
        margin-left: auto;
      }
    }

    .rsg-btn {
      width: 100%;
      height: 2.0481rem;
      background: rgba(0, 56, 121, 1);
      color: #fff;
      font-size: 0.8193rem;
      margin-bottom: 0.12rem;
    }
  }
}
</style>