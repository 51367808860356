<template>
  <div>
    <div class="depotbox">
      <!-- 我的仓库 -->
      <div class="serch">
        <div class="typebox">
          <div
            v-show="acorder_sn == ''"
            class="type-item flex-c"
            v-for="(item, index) in typedata"
            @click="dotype(item)"
            :key="index"
            :class="{ 'active-item': item.id == actype }"
          >
            {{ item.title }}
          </div>
          <div
            v-show="acorder_sn != ''"
            class="type-item flex-c"
            @click="showallclick"
          >
            Show all
          </div>
        </div>
        <div class="infotextbox">
          <p>
            If you have any questions,
            <span class="clicktext point" @click="kefuclick">
              please contact customer service</span
            >
          </p>
          <p>
            If the keys in shipment or have error,pls contact customer service
            for order <span class="bloe">refund</span> /
            <span class="bloe">replace</span>
          </p>
        </div>
      </div>
      <div class="goodslist">
        <div
          class="item point"
          v-for="(v, i) in goodslist"
          :key="i"
          @mouseenter="tipsshow(v.status_code, v.id, v.status)"
          @mouseleave="closeshow"
        >
          <div class="tips" v-if="v.status_code == 0 && v.status === 1">
            Not picked up
          </div>
          <div class="tips" v-if="v.status_code == 1">Picked up</div>
          <div
            class="tips"
            v-if="v.status === 0 && v.status_code == 0"
            style="background: #f6841c"
          >
            In Shipment
          </div>
          <div
            class="tips"
            v-if="v.status === 2 && v.status_code == 0"
            style="background: #8e8e8e"
          >
            Refunded
          </div>
          <div class="imgbox">
            <img :src="v.image" alt="" />
          </div>
          <div class="infobox">
            {{ v.title }}
          </div>
          <div class="num">
            <span>x</span>
            <h4>{{ v.nums }}</h4>
          </div>
          <div
            class="take"
            v-show="activeIndex == v.id && takeshow"
            @click="gotakeclick(v)"
          >
            <p>Take Delivery</p>
          </div>
          <div class="view" v-show="v.status_code == 1" @click="detaillook(v)">
            <p>View Details</p>
          </div>
        </div>
      </div>
      <div class="common-pagination">
        <el-pagination
          :current-page.sync="page"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="detailshow" v-show="detailshow">
      <div class="box" v-show="gotake">
        <!-- 提货弹窗 -->
        <div class="title">
          Take delivery
          <img
            class="point"
            src="@/assets/images/pc/pc-home-icon10.png"
            alt=""
            @click="
              () => {
                (this.gotake = false), (this.detailshow = false);
              }
            "
          />
        </div>
        <div class="main">
          <div class="taketips">Do you take delivery?</div>
          <div class="btnbox point">
            <div
              class="btn"
              @click="
                () => {
                  (this.gotake = false), (this.detailshow = false);
                }
              "
            >
              No
            </div>
            <div class="btn" @click="yestike">Yes</div>
          </div>
        </div>
      </div>
      <div class="box" v-show="godetail">
        <!-- 查看详情 -->
        <div class="title">
          Details
          <img
            class="point"
            src="@/assets/images/pc/pc-home-icon10.png"
            alt=""
            @click="
              () => {
                (this.godetail = false), (this.detailshow = false);
              }
            "
          />
        </div>
        <div class="main" v-if="d1 && activeIndex == id">
          <!--有检测结果  -->
          <div v-if="checkvalue">
            <div class="checkbox1">
              <div class="infotitle">
                <div style="width: 70%">Keys</div>
              </div>
              <div class="checkbox">
                <div
                  class="keysitem"
                  v-for="(item, index) in keysdata"
                  :key="index"
                >
                  <div style="width: 70%">{{ item.key }}</div>
                </div>
              </div>
              <div class="ebtnbox">
                <div
                  class="ebtn point"
                  style="margin: 0 0.5rem"
                  @click="ducheck(1)"
                >
                  Check
                </div>
                <div class="ebtn point" @click="exportExcel(1)">Export</div>
              </div>
              <div
                class="lodingbox"
                v-show="lodingshow"
                element-loading-spinner="el-icon-loading"
              >
                {{ loadingText }}
              </div>
            </div>
          </div>
          <!-- 用户检测完成 -->
          <div v-else>
            <div class="checkbox1">
              <div class="infotitle">
                <div style="width: 70%">Keys</div>
                <div style="width: 30%">Result</div>
              </div>
              <div class="checkbox">
                <div
                  class="keysitem"
                  v-for="(item, index) in checkKeydata"
                  :key="index"
                >
                  <div style="width: 70%">{{ item.key }}</div>
                  <div style="width: 30%">{{ item.Result }}</div>
                </div>
              </div>
              <div class="ebtnbox">
                <div
                  class="ebtn point"
                  style="margin: 0 0.5rem"
                  @click="ducheck(2)"
                >
                  Check
                </div>
                <div class="ebtn point" @click="exportExcel(2)">Export</div>
              </div>
              <div
                class="lodingbox"
                v-show="lodingshow"
                element-loading-spinner="el-icon-loading"
              >
                {{ loadingText }}
              </div>
            </div>
          </div>
        </div>
        <div class="main" v-if="d2">
          <!--无检测结果  -->
          <div class="infotitle">
            <div style="width: 70%">Keys</div>
          </div>
          <div class="checkbox">
            <div
              class="keysitem"
              v-for="(item, index) in keysdata"
              :key="index"
            >
              <div style="width: 70%">{{ item.key }}</div>
            </div>
          </div>
          <div class="ebtnbox">
            <div class="ebtn point" @click="exportExcel(1)">Export</div>
          </div>
        </div>
        <div class="main" v-if="d3">
          <div class="beanicon">
            <img src="../../assets/images/pc/pc-deopt-rollicon.png" alt="" />
          </div>
          <div class="beannums">{{ rollbean }} USDT</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getOrderdataApi,
  getOrderdetaildataApi,
  tiketipsApi,
  checkKeyApi,
} from "../../network/api";
import { mapState } from "vuex";
import { exportExcel } from "@/utils/excel.js";
export default {
  data() {
    return {
      takeshow: false, //提货弹出蒙层
      viewshow: false, //详情查看蒙层
      goodslist: [],
      type: "pay",
      activeIndex: null,
      detailshow: false, //详情蒙层
      gotake: false, // 去提货弹窗
      godetail: false, //详情查看弹窗
      d1: false, //有检测结果
      d2: false, //无检测结果
      d3: false, //roll 房
      page: 1, //页码
      pageSize: 10, //当前页面
      total: 1, //总条数

      keysdata: [], //详情展示数据
      tikeid: "", //提货id
      //等待状态的参数
      loading: false, //等待状态
      loadingText: "checking 1%",
      Timer: null,
      lodingshow: false, //等待中弹窗
      checkKeydata: [], //当前待检测钥匙
      codevalue: "",
      checkvalue: true, //控制当前是否需要检测
      id: null,
      num: 1,

      typedata: [
        {
          title: "pay",
          id: 1,
        },
        // {
        //   title: 'exchange',
        //   id: 2
        // },
        {
          title: "Reward",
          id: 3,
        },
      ],
      actype: 1,
      acorder_sn: "", //携带的订单号
      rollbean: null, //展示roll金币
    };
  },
  created() {
    if (this.$route.query.order_sn) {
      this.acorder_sn = this.$route.query.order_sn;
      console.log(this.acorder_sn);
      this.getdata();
    } else {
      this.getdata();
    }
  },
  methods: {
    showallclick() {
      this.acorder_sn = "";
      this.getdata();
    },
    //用户点击检测
    async ducheck(v) {
      if (v == 1) {
        this.codevalue = this.keysdata
          .map((v) => {
            return v.key;
          })
          .join("\r\n");
        if (this.loading) {
          this.$message.error("Pickup is in progress. Please wait.");
          return false;
        }
        if (this.Timer) {
          clearInterval(this.Timer);
        }
        this.lodingshow = true;
        this.Timer = setInterval(() => {
          if (this.num < 99) {
            this.num++;
            this.loadingText = `checking ${this.num}%`;
          }
        }, 1500);
        const res = await checkKeyApi(this.codevalue, "redeemms");
        this.checkKeydata = res.data.map((v) => {
          return {
            key: v.keyname,
            Result: v.status,
          };
        });
        if (this.checkKeydata.length > 0) {
          this.lodingshow = false;
          this.checkvalue = false;
          clearInterval(this.Timer);
          this.num = 1;
          this.loadingText = `checking 1%`;
        }
        if (res.code == 0) {
          this.$message.error(res.msg);
          this.lodingshow = false;
          this.num = 1;
          this.loadingText = `checking 1%`;
        }
      } else if (v == 2) {
        this.codevalue = this.keysdata
          .map((v) => {
            return v.key;
          })
          .join("\r\n");
        if (this.loading) {
          this.$message.error("Pickup is in progress. Please wait.");
          return false;
        }
        if (this.Timer) {
          clearInterval(this.Timer);
        }
        this.lodingshow = true;
        this.Timer = setInterval(() => {
          if (this.num < 99) {
            this.num++;
            this.loadingText = `checking ${this.num}%`;
          }
        }, 1500);
        const res = await checkKeyApi(this.codevalue, "redeemms");
        this.checkKeydata = res.data.map((v) => {
          return {
            key: v.keyname,
            Result: v.status,
          };
        });
        if (this.checkKeydata.length > 0) {
          this.lodingshow = false;
          this.checkvalue = false;
          clearInterval(this.Timer);
          this.num = 1;
          this.loadingText = `checking 1%`;
        }
        if (res.code == 0) {
          this.$message.error(res.msg);
          this.lodingshow = false;
          this.num = 1;
          this.loadingText = `checking 1%`;
        }
      }
    },
    kefuclick() {
      drift.api.toggleChat();
    },
    takefalse() {
      this.takeshow = false;
    },
    async getdata() {
      const res = await getOrderdataApi(this.page, this.type, this.acorder_sn);
      this.goodslist = res.data.Items;
      if (this.type == "roll") {
        this.goodslist = res.data.Items.map((v) => {
          if (v.goods_id == 1) {
            return {
              ...v,
            };
          }
          if (v.goods_id == 2) {
            return {
              ...v,
              status_code: 1,
            };
          }
        });
      }
      this.pageSize = res.data.ItemsPerPage;
      this.total = res.data.TotalItems;
    },
    tipsshow(v, c, d) {
      this.activeIndex = c;
      this.id = c;
      this.checkvalue = true;
      if (v == 0) {
        this.takeshow = true;
      }
      if (d == 0) {
        this.takeshow = false;
      }
      if (d == 2) {
        this.takeshow = false;
      }
    },
    closeshow() {
      this.takeshow = false;
      // this.viewshow = false
    },
    dotype(v) {
      this.type = v.title;
      if (v.title == "Reward") {
        this.type = "roll";
      }
      this.actype = v.id;
      this.getdata();
    },
    // 提货弹出
    gotakeclick(v) {
      // this.loadingindex = v.id
      this.detailshow = true;
      this.gotake = true;
      // console.log(v.id);
      this.tikeid = v.id;
    },
    //确认提货
    async yestike() {
      const params = {
        type: this.type,
        id: this.tikeid,
      };
      this.detailshow = false;
      this.gotake = false;

      const res = await tiketipsApi(params);
      if (res.code == 1) {
        this.getdata();
      } else if (res.code == 0) {
        this.$message.error(res.msg);
      }
    },
    // 详情弹出
    async detaillook(v) {
      this.detailshow = true;
      this.godetail = true;
      if (v.is_check == true) {
        this.d1 = true;
        this.d2 = false;
        this.d3 = false;
      } else {
        this.d2 = true;
        this.d1 = false;
        this.d3 = false;
      }
      if (this.type === "roll" && v.goods_id === 2) {
        this.d3 = true;
        this.d1 = false;
        this.d2 = false;
        this.rollbean = v.price * v.nums;
      }
      this.keysdata = [];
      const res = await getOrderdetaildataApi(this.type, v.id);
      if (res.code == 1) {
        this.keysdata = res.data.keys.map((item) => {
          return {
            key: item.key,
          };
        });
      }else{
        this.$message.error(res.msg)
      }
    },
    handleCurrentChange(val) {
      this.getdata(val, this.type);
    },
    //导出
    exportExcel(v) {
      if (v == 1) {
        const exc_data = [Object.keys(this.keysdata[0])];
        this.keysdata.forEach((item) => {
          exc_data.push(Object.values(item));
        });
        exportExcel("exported tables", exc_data);
      } else if (v == 2) {
        const exc_data = [Object.keys(this.checkKeydata[0])];
        this.checkKeydata.forEach((item) => {
          exc_data.push(Object.values(item));
        });
        exportExcel("exported tables", exc_data);
      }
    },
  },
  computed: {
    ...mapState(["onlineUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.depotbox {
  width: 10.93rem;
  height: 6.4rem;
  margin: 0.3rem auto;
  background: #f6faff;

  .serch {
    width: 10.55rem;
    margin: 0 auto;
    height: 0.9rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-right: 0.4rem;
    box-sizing: border-box;
    .typebox {
      border: 1px solid rgba(0, 93, 179, 0.1);
      box-shadow: #eee 0.02rem 0.02rem 0.1rem 0.02rem;
      padding: 0.08rem;
      display: flex;
      align-items: center;
      .type-item {
        // width: 0.89rem;
        height: 0.46rem;
        border: 1px solid rgba(0, 56, 121, 0.1);
        cursor: pointer;
        padding: 0 0.3rem;
        white-space: nowrap;
      }
      .type-item:hover {
        background: rgba(0, 56, 121, 0.1);
      }
      .active-item {
        background: rgba(0, 56, 121, 0.1);
      }
    }
    .infotextbox {
      .clicktext {
        color: #0076ff;
        text-decoration: underline;
      }
      p {
        margin: 0;
      }
      .bloe {
        font-weight: 700;
        font-size: 0.18rem;
      }
    }
  }

  .goodslist {
    width: 100%;
    height: 5.8rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 1.7rem;
      height: 2.4rem;
      background: #f4f4f4;
      border-radius: 0.04rem;
      position: relative;
      margin: 0 0.2rem;

      .tips {
        padding: 0 0.03rem;
        height: 0.2rem;
        background: #003879;
        border-radius: 0 0.04rem 0 0.04rem;
        font-size: 0.1rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.2rem;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
      }

      .imgbox {
        width: 1.4rem;
        height: 1.75rem;
        margin: 0.05rem auto;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .infobox {
        width: 1.35rem;
        height: 0.29rem;
        margin: 0 auto;
        font-size: 0.12rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 0.14rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .num {
        width: 0.33rem;
        height: 0.2rem;
        background: #bbdbff;
        border-radius: 0 0.04rem 0 0.04rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.05rem;
      }
    }

    .take {
      width: 1.7rem;
      height: 2.4rem;
      background: #000000;
      opacity: 0.5;
      border-radius: 0.04rem;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        width: 1.15rem;
        height: 0.25rem;
        font-size: 0.18rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.21rem;
      }
    }

    .view {
      width: 1.7rem;
      height: 2.4rem;
      background: rgba($color: #000000, $alpha: 0.2);
      border-radius: 0.04rem;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        width: 1.15rem;
        height: 0.25rem;
        font-size: 0.18rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 800;
        color: #fff;
        line-height: 0.21rem;
        opacity: 1;
        text-shadow: 0.02rem 0.05rem 0.04rem rgba(0, 0, 0, 1);
      }
    }
  }

  .common-pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.detailshow {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.2);
  z-index: 1999;

  .box {
    width: 8.5rem;

    .title {
      width: 100%;
      height: 0.8rem;
      background: #002550;
      text-align: center;
      line-height: 0.8rem;
      font-size: 0.28rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      border-radius: 0.12rem 0.12rem 0 0;
      position: relative;

      img {
        display: block;
        width: 0.35rem;
        height: 0.35rem;
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
      }
    }

    .main {
      width: 100%;
      background: #fff;
      border-radius: 0 0 0.12rem 0.12rem;
      overflow: hidden;
    }

    .taketips {
      width: 100%;
      height: 1.75rem;
      text-align: center;
      line-height: 1.75rem;
      font-size: 0.3rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #000000;
    }

    .btnbox {
      width: 100%;
      height: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .btn {
        width: 1.54rem;
        height: 0.4rem;
        background: #0076ff;
        border-radius: 0.02rem;
        font-size: 0.18rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        line-height: 0.4rem;
      }
    }

    .infotitle {
      width: 7.46rem;
      height: 0.6rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
      font-size: 0.2rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      color: #000000;
    }

    .checkbox1 {
      position: relative;
      .lodingbox {
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0.5;
        border-radius: 0.04rem;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }

    .checkbox {
      width: 7.46rem;
      height: 1.5rem;
      margin: 0 auto;
      overflow-y: scroll;
      font-size: 0.2rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #000000;

      .keysitem {
        display: flex;
        align-items: center;
        margin: 0.1rem 0;
      }
    }

    .ebtnbox {
      width: 7.46rem;
      height: 0.5rem;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .ebtn {
        width: 1.5rem;
        height: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #0076ff;
        color: #fff;
        border-radius: 0.02rem;
      }
    }
    .beanicon {
      width: 100%;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 1.82rem;
        height: 1.26rem;
      }
    }
    .beannums {
      text-align: center;
      font-size: 0.2rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0.4rem;
    }
  }
}
</style>
