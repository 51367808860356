<template>
  <div class="userbox">
    <div class="center" v-if="!isMinDevice">
      <div class="l_box point">
        <div class="title">personal center</div>
        <div
          class="item"
          v-for="(item, index) in titlelist"
          :key="index"
          @click="changetab(item)"
          :class="tabindex == item.id ? 'acitem' : ''"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="r_box">
        <userInfo v-if="this.tabindex == 1" />
        <depot v-else-if="this.tabindex == 2" />
        <purses v-else-if="this.tabindex == 3" />
        <point v-else-if="this.tabindex == 4" />
        <sensing v-else-if="this.tabindex == 5" />
        <orderList v-else-if="this.tabindex == 6" />
      </div>
    </div>
    <div v-else style="margin-top: 1rem">
      <div class="mb_top">
        <div class="mb_infobox">
          <div class="userhead">
            <img
              :src="onlineUrl + userInfo.avatar"
              alt=""
              class="point"
              @click="changename"
            />
            <div class="isvipbox">
              <img
                src="../../assets/images/pc/pc-user-isvip.png"
                v-if="isVip == true"
                alt=""
              />
              <img
                src="../../assets/images/pc/pc-user-novip.png"
                v-else
                alt=""
              />
            </div>
          </div>
          <div class="userlist">
            <div class="flex-s" style="position: relative">
              <p>Name：</p>
              <span class="listbg">{{ userInfo.nickname }}</span>
              <div class="amend point" @click="changename">Amend</div>
            </div>
            <div class="flex-s">
              <p>Email：</p>
              <span class="listbg1">{{ userInfo.email }}</span>
            </div>
          </div>
        </div>
        <div class="mb_codebox">
          <div class="balance">
            <!-- <p class="box">integral:{{ userInfo.score }}</p> -->
            <p class="box">balance:{{ userInfo.money }}</p>
            <p class="mb_rechange" @click="rechangeshow">recharge</p>
          </div>
          <p style="padding: 0 0.2rem; font-weight: 400; font-size: 0.71rem">
            <svg
              style="vertical-align: text-top"
              t="1701778847293"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="5178"
              width="20"
              height="20"
            >
              <path
                d="M574.171429 109.714286l438.857142 746.057143c21.942857 36.571429 7.314286 80.457143-25.6 98.742857-10.971429 7.314286-25.6 10.971429-36.571428 10.971428H73.142857c-40.228571 0-73.142857-32.914286-73.142857-73.142857 0-14.628571 3.657143-25.6 10.971429-36.571428L449.828571 109.714286c21.942857-36.571429 65.828571-47.542857 98.742858-25.6 10.971429 7.314286 21.942857 14.628571 25.6 25.6z"
                fill="#F9C014"
                p-id="5179"
              ></path>
              <path
                d="M512 687.542857c-14.628571 0-25.6-10.971429-25.6-25.6 0 0-58.514286-245.028571-58.514286-270.628571 0-43.885714 40.228571-84.114286 87.771429-84.114286 47.542857 0 87.771429 36.571429 87.771428 84.114286 0 18.285714-51.2 259.657143-58.514285 270.628571-7.314286 14.628571-18.285714 25.6-32.914286 25.6zM453.485714 804.571429c0 32.914286 25.6 58.514286 58.514286 58.514285 32.914286 0 58.514286-25.6 58.514286-58.514285 0-32.914286-25.6-58.514286-58.514286-58.514286-14.628571 0-29.257143 7.314286-40.228571 18.285714-14.628571 10.971429-18.285714 25.6-18.285715 40.228572z"
                fill="#FFFFFF"
                p-id="5180"
              ></path>
            </svg>
            The amount received must match exactly, otherwise it cannot be
            received! <br />
            <br />
            <svg
              style="vertical-align: text-top"
              t="1701779223840"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="13420"
              width="25"
              height="25"
            >
              <path
                d="M499.2 540.8m-384 0a384 384 0 1 0 768 0 384 384 0 1 0-768 0Z"
                fill="#FFAE44"
                p-id="13421"
              ></path>
              <path
                d="M428.8 755.2c0 12.8 9.6 22.4 22.4 22.4h96c12.8 0 22.4-9.6 22.4-22.4v-22.4h-140.8v22.4z m70.4-448c-92.8 0-166.4 73.6-166.4 166.4 0 57.6 28.8 105.6 70.4 134.4v54.4c0 12.8 9.6 22.4 22.4 22.4h140.8c12.8 0 22.4-9.6 22.4-22.4V608c41.6-28.8 70.4-80 70.4-134.4a160 160 0 0 0-160-166.4z m67.2 259.2l-22.4 16v54.4h-96v-54.4l-19.2-12.8c-32-22.4-51.2-57.6-51.2-96 0-64 54.4-118.4 118.4-118.4s118.4 54.4 118.4 118.4c3.2 35.2-16 73.6-48 92.8z"
                fill="#FFFFFF"
                p-id="13422"
              ></path></svg
            >Tip: Please ensure the amount paid matches the payment due exactly, Refresh the page 80 seconds afterpayment to confirm if the balance has been updated. If there are any issues, please contact customer service immediately.
          </p>
          <div class="mb_codeput">
            <div class="paytab">
              <img src="../../assets/images/pc/mb-user-paytype.png" alt="" />
            </div>
            <p class="mb_codetitle">Authentication Code</p>
            <div class="codeputbox" v-if="userInfo.parent_code == null">
              <input
                class="mb_codeputvule"
                type="text"
                placeholder="Please enter the authentication code"
                v-model="codevalu"
              />
              <div class="mb_save point" @click="bindcode">Save</div>
            </div>
            <div class="codeputbox" v-else>
              <input
                class="mb_codeputvule"
                type="text"
                disabled="disabled"
                v-model="userInfo.parent_code"
              />
              <div
                class="mb_save"
                style="background: #dfdfdf; cursor: not-allowed"
              >
                Save
              </div>
            </div>
          </div>
        </div>
        <div class="mb_tabbox">
          <div
            class="mb_tabitem"
            v-for="item in mbtitlelist"
            :key="item.id"
            @click="typeclick(item)"
            :class="typeindex == item.id ? 'acmb_item' : ''"
          >
            <img :src="item.img" alt="" />
            <p>{{ item.title }}</p>
          </div>
        </div>
        <div class="changeview" v-if="amend">
          <div
            class="mb_upbox"
            v-show="nameshow"
            :class="{ mb_box: isMinDevice }"
          >
            <div class="title" :class="{ mb_title: isMinDevice }">
              Modification Of Data
              <img
                class="point"
                src="../../assets/images/pc/pc-home-icon10.png"
                alt=""
                @click="
                  () => {
                    (this.amend = false), (this.nameshow = false);
                  }
                "
              />
            </div>
            <div class="mb_upcontentbox">
              <div class="mb_curhead">
                <div class="mb_uptop">
                  <img :src="onlineUrl + imageUrl" alt="" />
                  <div class="flex-c">
                    <input class="mb_putbox" type="text" v-model="name" />
                  </div>
                </div>
                <div class="mb_clickhead point">
                  <div
                    class="mb_headitem"
                    style="position: relative"
                    v-for="(item, index) in headlist"
                    :key="index"
                    @click="tabhead(item)"
                    :class="headindex == item.id ? 'acitem' : ''"
                  >
                    <img :src="onlineUrl + item.img" alt="" />
                  </div>
                  <div class="mb_headitem point" style="position: relative">
                    <img
                      src="../../assets/images/pc/pc-user-addhead.png"
                      alt=""
                    />
                    <input
                      class="point"
                      type="file"
                      ref="uploader"
                      @change="handleAvatarSuccess"
                      id="upload"
                      style="
                        style=width: 1.2rem;
                        height: 2.5rem;
                        opacity: 0;
                        position: absolute;
                        left: 0;
                        top: 0;
                      "
                      accept="image/*"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mb_changebox flex-c">
              <div class="mb_amend2 point" @click="entename">Save</div>
            </div>
          </div>
        </div>
        <div class="changeview" v-if="changeshow">
          <div
            v-loading="loading"
            element-loading-text="Order is being created..."
            class="box"
            :class="{ mb_box: isMinDevice }"
          >
            <!-- 确认充值 -->
            <div class="title" :class="{ mb_title: isMinDevice }">
              Recharge
              <img
                class="point"
                src="@/assets/images/pc/pc-home-icon10.png"
                alt=""
                @click="
                  () => {
                    this.changeshow = false;
                  }
                "
              />
            </div>
            <div class="main">
              <div class="rechangebox" :class="{ mb_rechangebox: isMinDevice }">
                <div
                  style="padding-left: 0.3rem"
                  class="online-title"
                  :class="{ 'mb_online-title': isMinDevice }"
                >
                  Online recharge(Only supports virtual currency)
                </div>
                <div
                  style="padding: 0 0.3rem; margin-bottom: 0.3rem"
                  class="flex-s"
                >
                  <div
                    class="input-box"
                    :class="{ mb_inputbox: isMinDevice }"
                    style="margin-right: 0.2rem"
                  >
                    <span>USDT</span>
                    <el-input-number
                      class="input-nums"
                      :class="{ mb_input_num: isMinDevice }"
                      placeholder="Please enter the amount"
                      :controls="false"
                      v-model="amount"
                      :min="0"
                      :max="99999"
                      label="input"
                    ></el-input-number>
                  </div>
                  <div
                    @click="rechargeOnClick"
                    class="btn point"
                    :class="{ mb_changebtn: isMinDevice }"
                  >
                    recharge
                  </div>
                </div>

                <p :style="isMinDevice ? 'font-size:0.6rem' : ''">
                  If you need other methods of payment, or if you have any
                  questions, please contact customer service
                </p>
                <a
                  class="tobox t1"
                  :class="{ mb_tobox: isMinDevice }"
                  href="https://join.skype.com/invite/bBDIX4DdFUQK"
                  target="_blank"
                  ><img
                    :style="isMinDevice ? 'width: 3.3rem;height: 0.8rem;' : ''"
                    src="@/assets/images/pc/pc-user-rechange-icon1.png"
                    alt=""
                  />
                  <span>ACE recharge service(24-hour service)</span>
                </a>
                <a
                  class="tobox t2"
                  :class="{ mb_tobox: isMinDevice }"
                  href="https://t.me/ACESOFTKEYS"
                  target="_blank"
                  ><img
                    :style="isMinDevice ? 'width: 3.3rem;height: 0.8rem;' : ''"
                    src="@/assets/images/pc/pc-user-rechange-icon2.png"
                    alt=""
                  />
                  <span>@ACE_keys</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb_bottom">
        <mbdepot v-if="this.tabindex == 2" />
        <purses v-else-if="this.tabindex == 3" />
        <!-- <point v-else-if="this.tabindex == 4" /> -->
        <sensing v-else-if="this.tabindex == 5" />
        <orderList v-else-if="this.tabindex == 6" />
      </div>
    </div>
  </div>
</template>

<script>
import userInfo from "./userInfo.vue";
import depot from "./depot.vue";
import purses from "./purses.vue";
import sensing from "./sensing.vue";
import orderList from "./orderList.vue";
import point from "./point.vue";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import {
  setInviterApi,
  SetprofileApi,
  UploadApi,
  rechargeApi,
  LogoutApi,
} from "../../network/api";
import mbdepot from "./mbdepot.vue";
export default {
  components: {
    userInfo,
    depot,
    purses,
    sensing,
    orderList,
    point,
    mbdepot,
  },
  data() {
    return {
      titlelist: [
        {
          title: "My Profile",
          id: 1,
        },
        {
          title: "My warehouse",
          id: 2,
        },
        {
          title: "Wallet records",
          id: 3,
        },
        // {
        //   title: "Integral Record",
        //   id: 4,
        // },
        {
          title: "Key Check",
          id: 5,
        },
        {
          title: "Order Record",
          id: 6,
        },
        {
          title: "logout",
          id: 7,
        },
      ],
      tabindex: 1,
      // 手机端标题列表
      mbtitlelist: [
        {
          title: "My warehouse",
          img: require("../../assets/images/pc/mb-user-icon1.png"),
          id: 2,
        },
        {
          title: "Wallet records",
          img: require("../../assets/images/pc/mb-user-icon2.png"),
          id: 3,
        },
        // {
        //   title: "Integral Record",
        //   img: require('../../assets/images/pc/mb-user-icon3.png'),
        //   id: 4,
        // },
        {
          title: "Key Check",
          img: require("../../assets/images/pc/mb-user-icon4.png"),
          id: 5,
        },
        {
          title: "Order Record",
          img: require("../../assets/images/pc/mb-user-icon5.png"),
          id: 6,
        },
        {
          title: "logout",
          img: require("../../assets/images/pc/mb-user-icon6.png"),
          id: 7,
        },
      ],
      typeindex: null,
      //个人信息的
      codevalu: "",
      amend: false, //修改蒙层
      name: "",
      nameshow: false, //修改昵称
      headshow: false, //修改头像
      headlist: [
        {
          img: "/uploads/20231204/fe1e0ed1101c6c5801e7e1316dd03632.png",
          id: 1,
        },
        {
          img: "/uploads/20231204/0e99a2eedb74eb7ff46f1165be634d77.png",
          id: 2,
        },
      ],
      headindex: 1,
      imageUrl: "",
      changeshow: false,
      amount: 0,
      loading: false,
    };
  },
  created() {
    if (this.$route.query.TabIndex) {
      this.tabindex = this.$route.query.TabIndex;
      this.typeindex = this.$route.query.TabIndex;
    } else {
      this.tabindex = 1;
    }
  },
  computed: {
    ...mapState(["isMinDevice", "userInfo", "onlineUrl"]),
    ...mapGetters(["isVip"]),
  },
  mounted() {
    setTimeout(() => {
      document.body.scrollIntoView(".userbox");
    }, 0);
  },
  methods: {
    // 手机端切换
    async typeclick(v) {
      this.typeindex = v.id;
      this.tabindex = v.id;
      this.$router.push({
        path: "/User",
        query: {
          TabIndex: this.typeindex,
        },
      });
      if (v.id == "7") {
        const res = await LogoutApi();
        localStorage.removeItem("isShowTip");
        localStorage.removeItem("userInfo");
        this.clearToken();
        window.location.replace("/Home");
      }
    },
    async changetab(v) {
      this.tabindex = v.id;
      this.$router.push({
        path: "/User",
        query: {
          TabIndex: this.tabindex,
        },
      });
      if (v.id == "7") {
        const res = await LogoutApi();
        localStorage.removeItem("isShowTip");
        localStorage.removeItem("userInfo");
        this.clearToken();
        window.location.replace("/Home");
      }
    },
    ...mapMutations(["setUserInfo"]),
    ...mapActions(["ReFreshUserInfo"]),
    async rechargeOnClick() {
      try {
        this.loading = true;
        const params = {
          amount: this.amount,
        };
        const res = await rechargeApi(params);
        if (res.code === 1 && res.data?.url) {
          window.open(res.data?.url, "_blank");
          this.amount = 0;
          this.changeshow = false;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    // 绑定邀请人
    async bindcode() {
      if (this.codevalu != "") {
        const res = await setInviterApi(this.codevalu);
        if (res.code == 1) {
          this.ReFreshUserInfo();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    async handleAvatarSuccess(e) {
      const params = new FormData();
      const file = e.target.files[0];
      params.append("file", file);
      const res = await UploadApi(params);
      this.imageUrl = res.data.url;
    },
    // 修改昵称弹窗
    changename() {
      this.amend = true;
      this.nameshow = true;
      this.name = this.userInfo.nickname;
      this.imageUrl = this.userInfo.avatar;
    },
    // 确认修改昵称
    entename() {
      if (this.name != "") {
        SetprofileApi(this.name, this.imageUrl).then((res) => {
          if (res.code == 1) {
            this.amend = false;
            this.nameshow = false;
            this.ReFreshUserInfo();
          }
          (r) => {
            if (r.code != 1) {
              this.$message.error(r.msg);
            }
          };
        });
      }
    },
    //修改头像弹窗
    changehead() {
      this.imageUrl = this.userInfo.avatar;
      this.amend = true;
      this.headshow = true;
    },
    tabhead(v) {
      this.headindex = v.id;
      this.imageUrl = v.img;
    },

    entehead() {
      SetprofileApi(this.name, this.imageUrl).then((res) => {
        if (res.code == 1) {
          this.amend = false;
          this.nameshow = false;
          this.ReFreshUserInfo();
          this.imageUrl = "";
        }
        (r) => {
          if (r.code != 1) {
            this.$message.error(r.msg);
          }
        };
      });
    },
    //充值
    rechangeshow() {
      this.changeshow = true;
    },

    ...mapMutations(["clearToken"]),
  },
  watch: {
    $route: {
      handler(newVal) {
        this.tabindex = newVal.query.TabIndex;
        this.typeindex = newVal.query.TabIndex;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.userbox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 0.5rem;
  .center {
    width: 14.6rem;
    margin: 0.9rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .l_box {
      width: 2.83rem;
      height: 7.54rem;
      box-shadow: 0 0.04rem 0.04rem 0.04rem rgba(0, 0, 0, 0.1);

      .title {
        width: 100%;
        height: 0.72rem;
        background: #003879;
        border-radius: 0.06rem 0.06rem 0 0;
        text-align: center;
        line-height: 0.72rem;
        font-size: 0.18rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }

      .item {
        width: 100%;
        height: 0.72rem;
        text-align: center;
        line-height: 0.72rem;
        color: #595959;
        font-size: 0.18rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
      }

      .acitem {
        background: #e6ebf2;
        color: #000000;
      }
    }

    .r_box {
      width: 11.53rem;
      height: 7.54rem;
      box-shadow: 0.04rem 0.04rem 0.04rem 0.04rem rgba(0, 0, 0, 0.1);
      border-radius: 0.06rem;
    }
  }
}
.mb_top {
  width: 16.5376rem;
  margin: 0 auto;
  .mb_infobox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .userhead {
      width: 4.71rem;
      height: 4.71rem;
      position: relative;
      border-radius: 50%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .isvipbox {
        width: 2.86rem;
        height: 0.9rem;
        position: absolute;
        bottom: -0.3rem;
        right: 20%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 0%;
        }
      }
    }
    .userlist {
      width: 10.7rem;
      height: 3rem;
      p {
        font-size: 0.61rem;
      }
      .listbg {
        width: 4.8rem;
        height: 1.024rem;
        background: #f5f5f5;
        text-align: center;
        line-height: 1.024rem;
        font-size: 0.6rem;
      }
      .listbg1 {
        width: 8.39rem;
        height: 1.024rem;
        background: #f5f5f5;
        text-align: center;
        line-height: 1.024rem;
        font-size: 0.6rem;
      }

      .amend {
        width: 3.27rem;
        height: 1.0752rem;
        border-radius: 0.1rem;
        background: #0076ff;
        position: absolute;
        right: 0.2rem;
        font-size: 0.6rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 1.0752rem;
        text-align: center;
      }
    }
  }
  .mb_codebox {
    width: 100%;
    .balance {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 1rem;
      border-bottom: 0.1rem solid #dfdfdf;
      .box {
        width: 5.8rem;
        height: 1.024rem;
        text-align: center;
        line-height: 1.024rem;
        margin: 0 0.45rem;
        font-size: 0.7rem;
        font-weight: 600;
      }
      .mb_rechange {
        width: 7rem;
        height: 1.5rem;
        font-size: 0.7rem;
        text-align: center;
        line-height: 1.5rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        background: url("../../assets/images/pc/pc-user-rechangebg.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .mb_codeput {
    width: 100%;
    height: 7.6rem;
    background: #f6faff;
    overflow: hidden;
    .paytab {
      width: 16.17rem;
      height: 2.8rem;
      margin: 0.6rem auto;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .mb_codetitle {
      font-size: 0.7rem;
      font-weight: 600;
      margin-left: 0.6rem;
      margin-top: 1rem;
    }
    .codeputbox {
      width: 15.3rem;
      .mb_codeputvule {
        width: 13rem;
        height: 1.024rem;
        font-size: 0.6rem;
        border: 0;
        text-decoration: none;
        padding-left: 0.6rem;
      }
      .mb_save {
        width: 2.66rem;
        height: 1.07rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.6rem;
        background: #0076ff;
        color: #ffffff;
        margin-left: 0.3rem;
        border-radius: 0.1rem;
      }
    }
  }
  .mb_tabbox {
    width: 100%;
    height: 7.37rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 0.5rem;
    .mb_tabitem {
      width: 5.32rem;
      height: 3.5rem;
      box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.1);
      border-radius: 0.2rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 0.095rem;
      img {
        width: 2.2rem;
        height: 2.2rem;
      }
      p {
        font-size: 0.7rem;
        margin: 0;
        font-weight: 400;
      }
    }
    .acmb_item {
      background: #d3eafb;
    }
  }
  .mb_box {
    width: 17.2rem;
    .mb_title {
      height: 1.5rem;
      font-size: 0.7rem;
      line-height: 1.5rem;
      border-radius: 0.2rem 0.2rem 0 0;
      img {
        width: 0.87rem;
        height: 0.87rem;
      }
    }
    .mb_rechangebox {
      width: 15.25rem;
      margin: 0 auto;
      .mb_online-title {
        margin-top: 0.4rem;
        width: 100%;
        font-size: 0.6rem;
      }
      .mb_inputbox {
        width: 100%;
        height: 2.35rem;
        font-size: 0.7rem;
        .mb_input_num {
          width: 8.55rem;
          height: 1.12rem;
          text-align: left;
          line-height: 1.12rem;
          .el-input {
            width: 100%;
            .el-input__inner {
              border: none;
              text-align: left;
              width: 100%;
              font-size: 0.6rem !important;
            }
            .el-input__inner::placeholder {
              font-size: 0.6rem !important;
            }
          }
        }
      }
      .mb_changebtn {
        width: 4.6rem;
        height: 1.126rem;
        font-size: 0.6rem;
      }
      .mb_tobox {
        width: 15.25rem;
        height: 1.45rem;
        font-size: 0.5rem;
        padding-left: 1rem;
        border-radius: 0.1rem;
      }
    }
  }
  .mb_upbox {
    width: 17.2rem;
    background: #fff;
    .mb_upcontentbox {
      width: 9.6rem;
      margin: 0 auto;
      .mb_curhead {
        padding-top: 0.5rem;
        .mb_uptop {
          display: flex;
          justify-content: space-between;
          align-content: center;
          img {
            width: 2.56rem;
            height: 2.56rem;
            border-radius: 50%;
            border: 0.1rem solid #0076ff;
          }
          .mb_putbox {
            width: 6rem;
            height: 1.075rem;
            font-size: 0.7rem;
          }
        }
      }
      .mb_clickhead {
        margin-top: 0.7rem;
        display: flex;
        justify-content: space-between;
        .mb_headitem {
          width: 2.56rem;
          height: 2.56rem;
          img {
            width: 2.56rem;
            height: 2.56rem;
            border-radius: 50%;
          }
        }
      }
    }
    .mb_changebox {
      width: 100%;
      height: 2.5rem;
      .mb_amend2 {
        width: 4.7rem;
        height: 1.075rem;
        background: #0076ff;
        font-size: 0.6rem;
        border-radius: 0.1rem;
        text-align: center;
        line-height: 1.075rem;
        color: #ffffff;
      }
    }
  }
}
</style>
