<template>
  <div>
    <div class="infobox-view">
      <div class="userhead">
        <img :src="onlineUrl + userInfo.avatar" alt="" class="point" @click="changehead" />
        <div class="isvipbox">
          <img src="../../assets/images/pc/pc-user-isvip.png" v-if="isVip == true" alt="" />
          <img src="../../assets/images/pc/pc-user-novip.png" v-else alt="" />
        </div>
      </div>
      <div class="userlist">
        <div class="flex-c" style="position: relative">
          <p>Name：</p>
          <span class="listbg">{{ userInfo.nickname }}</span>
          <div class="amend point" @click="changename">Amend</div>
        </div>
        <div class="flex-c">
          <p>Email：</p>
          <span class="listbg">{{ userInfo.email }}</span>
        </div>
      </div>
    </div>
    <div class="codebox">
      <div class="balance">
        <!-- <p class="box">integral:{{ userInfo.score }}</p> -->
        <p class="box">balance:{{ userInfo.money }}</p>
        <p class="box rechange" @click="rechangeshow">recharge</p>
      </div>
      <p style="padding:0 0.2rem;font-weight:700;font-size: 0.18rem;"> <svg style="vertical-align: text-top;" t="1701778847293" class="icon" viewBox="0 0 1024 1024" version="1.1" p-id="5178" width="20" height="20">
          <path
            d="M574.171429 109.714286l438.857142 746.057143c21.942857 36.571429 7.314286 80.457143-25.6 98.742857-10.971429 7.314286-25.6 10.971429-36.571428 10.971428H73.142857c-40.228571 0-73.142857-32.914286-73.142857-73.142857 0-14.628571 3.657143-25.6 10.971429-36.571428L449.828571 109.714286c21.942857-36.571429 65.828571-47.542857 98.742858-25.6 10.971429 7.314286 21.942857 14.628571 25.6 25.6z"
            fill="#F9C014" p-id="5179"></path>
          <path
            d="M512 687.542857c-14.628571 0-25.6-10.971429-25.6-25.6 0 0-58.514286-245.028571-58.514286-270.628571 0-43.885714 40.228571-84.114286 87.771429-84.114286 47.542857 0 87.771429 36.571429 87.771428 84.114286 0 18.285714-51.2 259.657143-58.514285 270.628571-7.314286 14.628571-18.285714 25.6-32.914286 25.6zM453.485714 804.571429c0 32.914286 25.6 58.514286 58.514286 58.514285 32.914286 0 58.514286-25.6 58.514286-58.514285 0-32.914286-25.6-58.514286-58.514286-58.514286-14.628571 0-29.257143 7.314286-40.228571 18.285714-14.628571 10.971429-18.285714 25.6-18.285715 40.228572z"
            fill="#FFFFFF" p-id="5180"></path>
        </svg> The amount received must match exactly, otherwise it cannot be received! <br />

        <svg style="vertical-align: text-top;" t="1701779223840" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13420" width="25" height="25">
          <path d="M499.2 540.8m-384 0a384 384 0 1 0 768 0 384 384 0 1 0-768 0Z" fill="#FFAE44" p-id="13421"></path>
          <path
            d="M428.8 755.2c0 12.8 9.6 22.4 22.4 22.4h96c12.8 0 22.4-9.6 22.4-22.4v-22.4h-140.8v22.4z m70.4-448c-92.8 0-166.4 73.6-166.4 166.4 0 57.6 28.8 105.6 70.4 134.4v54.4c0 12.8 9.6 22.4 22.4 22.4h140.8c12.8 0 22.4-9.6 22.4-22.4V608c41.6-28.8 70.4-80 70.4-134.4a160 160 0 0 0-160-166.4z m67.2 259.2l-22.4 16v54.4h-96v-54.4l-19.2-12.8c-32-22.4-51.2-57.6-51.2-96 0-64 54.4-118.4 118.4-118.4s118.4 54.4 118.4 118.4c3.2 35.2-16 73.6-48 92.8z"
            fill="#FFFFFF" p-id="13422"></path>
        </svg>Tip: Please ensure the amount paid matches the payment due exactly, Refresh the page 80 seconds afterpayment to confirm if the balance has been updated. If there are any issues, please contact customer service immediately.
      </p>
      <div class="paytab">
        <img src="../../assets/images/pc/pc-user-change.png" alt="" />
      </div>
      <p class="codetitle">Authentication Code</p>
      <div class="codeputbox" v-if="userInfo.parent_code == null">
        <input class="codeput" type="text" placeholder="Please enter the authentication code" v-model="codevalu" />
        <div class="save point" @click="bindcode">Save</div>
      </div>
      <div class="codeputbox" v-else>
        <input class="codeput" type="text" disabled="disabled" v-model="userInfo.parent_code" />
        <div class="save " style="background: #dfdfdf;cursor: not-allowed">Save</div>
      </div>
    </div>
    <div class="changeview" v-if="amend">
      <div class="namebox" v-show="nameshow">
        <div class="title">
          Changing Nickname
          <img class="point" src="../../assets/images/pc/pc-home-icon10.png" alt="" @click="() => {
            (this.amend = false), (this.nameshow = false);
          }
            " />
        </div>
        <div class="flex-c">
          <input class="putbox" type="text" v-model="name" />
        </div>
        <div class="flex-c">
          <div class="amend2 point" @click="entename">Amend</div>
        </div>
      </div>
      <div class="headbox" v-show="headshow">
        <div class="title">
          Changing Head Portrait
          <img class="point" src="@/assets/images/pc/pc-home-icon10.png" alt="" @click="() => {
            (this.amend = false), (this.headshow = false);
          }
            " />
        </div>
        <div class="flex-c">
          <div class="curhead">
            <img :src="onlineUrl + imageUrl" alt="" />
            <div class="curtitle">Current avatar</div>
          </div>
          <div class="silod"></div>
          <div class="clickhead point">
            <div class="item" style="position: relative" v-for="(item, index) in headlist" :key="index" @click="tabhead(item)" :class="headindex == item.id ? 'acitem' : ''">
              <img :src="onlineUrl + item.img" alt="" />
            </div>
            <div class="item point">
              <img src="../../assets/images/pc/pc-user-addhead.png" alt="" />
              <input class="point" type="file" ref="uploader" @change="handleAvatarSuccess" id="upload" style="
                  style=width: 1.2rem;
                  height: 1.2rem;
                  opacity: 0;
                  position: absolute;
                  left: 0;
                  top: 0;
                " accept="image/*" />
            </div>
          </div>
        </div>
        <div class="flex-c">
          <div class="amend2 point" @click="entehead">Amend</div>
        </div>
      </div>
    </div>
    <div class="changeview" v-if="changeshow">
      <div v-loading="loading" element-loading-text="Order is being created..." class="box">
        <!-- 确认充值 -->
        <div class="title">
          Recharge
          <img class="point" src="@/assets/images/pc/pc-home-icon10.png" alt="" @click="() => {
            this.changeshow = false;
          }
            " />
        </div>
        <div class="main">
          <div class="rechangebox">
            <div style="padding-left:0.3rem;" class="online-title">Online recharge(Only supports virtual currency)</div>
            <div style="padding:0 0.3rem;margin-bottom:0.3rem" class="flex-s">
              <div class="input-box" style="margin-right:0.2rem;">
                <span>USDT</span> <el-input-number class="input-num" placeholder="Please enter the amount" :controls="false" v-model="amount" :min="0" :max="99999" label="input"></el-input-number>
              </div>
              <div @click="rechargeOnClick" class="btn point ">recharge</div>
            </div>

            <p>
              If you need other methods of payment, or if you have any questions, please contact customer service
            </p>
            <a class="tobox t1" href="https://join.skype.com/invite/bBDIX4DdFUQK" target="_blank"><img src="@/assets/images/pc/pc-user-rechange-icon1.png" alt="" />
              <span>ACE recharge service(24-hour service)</span>
            </a>
            <a class="tobox t2" href="https://t.me/ACESOFTKEYS" target="_blank"><img src="@/assets/images/pc/pc-user-rechange-icon2.png" alt="" />
              <span>@ACE_keys</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { setInviterApi, SetprofileApi, UploadApi, rechargeApi } from "../../network/api";
export default {
  data() {
    return {
      codevalu: "",
      amend: false, //修改蒙层
      name: "",
      nameshow: false, //修改昵称
      headshow: false, //修改头像
      headlist: [
        {
          img: "/uploads/20231204/fe1e0ed1101c6c5801e7e1316dd03632.png",
          id: 1,
        },
        {
          img: "/uploads/20231204/0e99a2eedb74eb7ff46f1165be634d77.png",
          id: 2,
        },
      ],
      headindex: 1,
      imageUrl: "",
      changeshow: false,
      amount: 0,
      loading: false
    };
  },

  computed: {
    ...mapState(["userInfo", "onlineUrl"]),
    ...mapGetters(["isVip"]),
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    ...mapActions(["ReFreshUserInfo"]),
    async rechargeOnClick() {
      try {
        this.loading = true;
        const params = {
          amount: this.amount
        };
        const res = await rechargeApi(params);
        if (res.code === 1 && res.data?.url) {
          window.open(res.data?.url, '_blank');
          this.amount = 0;
          this.changeshow = false;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    // 绑定邀请人
    async bindcode() {
      if (this.codevalu != "") {
        const res = await setInviterApi(this.codevalu);
        if (res.code == 1) {
          this.ReFreshUserInfo();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    async handleAvatarSuccess(e) {
      const params = new FormData();
      const file = e.target.files[0];
      params.append("file", file);
      const res = await UploadApi(params);
      this.imageUrl = res.data.url;
    },
    // 修改昵称弹窗
    changename() {
      this.amend = true;
      this.nameshow = true;
      this.name = this.userInfo.nickname;
    },
    // 确认修改昵称
    entename() {
      if (this.name != "") {
        SetprofileApi(this.name, this.userInfo.avatar).then((res) => {
          if (res.code == 1) {
            this.amend = false;
            this.nameshow = false;
            this.ReFreshUserInfo();
          }
          (r) => {
            if (r.code != 1) {
              this.$message.error(r.msg);
            }
          };
        });
      }
    },
    //修改头像弹窗
    changehead() {
      this.imageUrl = this.userInfo.avatar;
      this.amend = true;
      this.headshow = true;
    },
    tabhead(v) {
      this.headindex = v.id;
      this.imageUrl = v.img;
    },

    entehead() {
      SetprofileApi(this.name, this.imageUrl).then((res) => {
        if (res.code == 1) {
          this.amend = false;
          this.nameshow = false;
          this.ReFreshUserInfo();
          this.imageUrl = "";
        }
        (r) => {
          if (r.code != 1) {
            this.$message.error(r.msg);
          }
        };
      });
    },
    //充值
    rechangeshow() {
      this.changeshow = true;
    },
  },
};
</script>

<style lang="scss">
.infobox-view {
  width: 10.23rem;
  height: 1.5rem;
  margin: 0.65rem auto 0.59rem;
  display: flex;
  align-items: center;

  .userhead {
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    border-radius: 50%;
    margin-right: 0.8rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .isvipbox {
      width: 1.03rem;
      height: 0.26rem;
      position: absolute;
      bottom: -0.05rem;
      right: 18%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 0%;
      }
    }

    .amend {
      width: 1.03rem;
      height: 0.26rem;
      border-radius: 0.02rem;
      background: #0076ff;
      position: absolute;
      bottom: 0;
      right: 16%;
      font-size: 0.18rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.26rem;
      text-align: center;
    }
  }

  .userlist {
    width: 2.71rem;

    .listbg {
      width: 2.05rem;
      height: 0.26rem;
      background: #f5f5f5;
      text-align: center;
      line-height: 0.26rem;
    }

    .amend {
      width: 1.03rem;
      height: 0.26rem;
      border-radius: 0.02rem;
      background: #0076ff;
      position: absolute;
      right: -1.47rem;
      font-size: 0.18rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.26rem;
      text-align: center;
    }
  }
}

.codebox {
  width: 10.23rem;
  padding-bottom: 0.2rem;
  background: #f6faff;
  margin: 0 auto;

  .balance {
    width: 100%;
    height: 0.65rem;
    display: flex;
    align-items: center;

    .box {
      width: 1.6rem;
      height: 0.4rem;
      text-align: center;
      line-height: 0.4rem;
      margin: 0 0.45rem;
      font-size: 0.25rem;
      font-weight: 600;
    }
  }

  .codetitle {
    margin-left: 0.4rem;
    height: 0.34rem;
    font-size: 0.24rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 0.28rem;
  }

  .paytab {
    width: 9.53rem;
    height: 0.5rem;
    margin: 0.4rem auto;

    img {
      width: 100%;
    }
  }
}

.codeputbox {
  width: 9.53rem;
  height: 0.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .codeput {
    width: 7.94rem;
    height: 0.5rem;
    border: 0;
    text-decoration: none;
    font-size: 0.2rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #b5b5b5;
    padding-left: 0.2rem;
    box-sizing: border-box;

    &::placeholder {
      font-size: 0.2rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #b5b5b5;
    }
  }

  .save {
    width: 1.29rem;
    height: 0.5rem;
    background: #0076ff;
    font-size: 0.24rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 0.5rem;
    text-align: center;
  }
}

.rechange {
  font-size: 0.24rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.35rem;
  cursor: pointer;
  background: url("../../assets/images/pc/pc-user-rechangebg.png") no-repeat;
  background-size: 100% 100%;
}

.changeview {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.2);
  z-index: 1999;

  .title {
    width: 100%;
    height: 0.8rem;
    background: #002550;
    text-align: center;
    line-height: 0.8rem;
    font-size: 0.28rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    border-radius: 0.12rem 0.12rem 0 0;
    position: relative;

    img {
      display: block;
      width: 0.35rem;
      height: 0.35rem;
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
    }
  }

  .amend2 {
    width: 3.18rem;
    height: 0.6rem;
    border-radius: 0.04rem;
    background: #0076ff;
    font-size: 0.2rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.6rem;
    text-align: center;
  }

  .namebox {
    width: 8.5rem;
    height: 3.17rem;
    border-radius: 0.12rem;
    background: #fff;

    .putbox {
      width: 6.9rem;
      height: 0.64rem;
      margin: 0.2rem auto;
      border: 0.01rem solid #b7b7b7;
      text-decoration: none;
      text-align: center;
      font-size: 0.28rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #848484;
    }
  }

  .headbox {
    width: 8.5rem;
    height: 3.17rem;
    border-radius: 0.12rem;
    background: #fff;

    .curhead {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      box-sizing: border-box;
      border: 0.04rem solid #0076ff;
      position: relative;

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .curtitle {
        position: absolute;
        bottom: -0.05rem;
        width: 1.12rem;
        height: 0.2rem;
        background: #0076ff;
        color: #fff;
        text-align: center;
        line-height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
      }
    }

    .silod {
      width: 0rem;
      height: 1.2rem;
      border: 0.01rem solid #e4e8ec;
      margin: 0 0.6rem;
    }

    .clickhead {
      width: 4rem;
      height: 1.2rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        box-sizing: border-box;
        position: relative;

        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .acitem {
        border: 0.02rem solid #0076ff;
        border-radius: 50%;
      }
    }
  }

  .box {
    width: 8.5rem;

    .title {
      width: 100%;
      height: 0.8rem;
      background: #002550;
      text-align: center;
      line-height: 0.8rem;
      font-size: 0.28rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      border-radius: 0.12rem 0.12rem 0 0;
      position: relative;

      img {
        display: block;
        width: 0.35rem;
        height: 0.35rem;
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
      }
    }

    .main {
      width: 100%;
      background: #fff;
      border-radius: 0 0 0.12rem 0.12rem;
    }

    .rechangebox {
      width: 5.1rem;
      margin: 0 auto;
      overflow: hidden;
      .online-title {
        margin-bottom: 0.1rem;
        margin-top: 0.1rem;
        font-size: 0.18rem;
        font-weight: bold;
      }
      .input-box {
        width: 100%;
        display: flex;
        align-items: center;
        span {
          font-weight: 800;
          margin-right: 0.2rem;
        }
        .input-num {
          width: 100%;
          border: 1px solid #dcdfe6;
          border-radius: 0.03rem;
          box-sizing: border-box;
          line-height: unset;
          height: 0.32rem;
          flex-grow: 1;

          .el-input {
            width: 100%;
            .el-input__inner {
              border: none;
              text-align: left;
              width: 100%;
              font-size: 0.18rem;
            }
            .el-input__inner::placeholder {
              font-size: 0.18rem;
            }
          }
        }
      }
      .btn {
        border: 1px solid #269ad2;
        background: #269ad2;
        padding: 0rem 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 0.03rem;
        height: 0.32rem;
        box-sizing: border-box;
      }

      .tobox {
        display: flex;
        width: 4.6rem;
        height: 0.4rem;
        margin: 0.2rem auto;
        align-items: center;
        font-size: 0.16rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        box-sizing: border-box;
        padding-left: 0.16rem;

        span {
          margin-left: 0.2rem;
        }
      }

      .t1 {
        background: #3773b8;
      }

      .t2 {
        background: #269ad2;
      }

      P {
        text-align: center;
      }
    }
  }
}
</style>