<template>
  <div class="view-mall-detail" :class="{ 'mb-view-mall-detail': isMinDevice }">
    <div v-if="!isMinDevice" class="bread-cumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item>Shop</el-breadcrumb-item>
        <el-breadcrumb-item>{{ goodDetail.category_text }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ goodDetail.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div
      class="header-img-title m-b-75"
      :class="{ 'mb-header-img-title': isMinDevice }">
      <div class="header-left">
        <img class="img h100" :src="goodDetail?.image" alt="" srcset="" />
      </div>
      <div class="header-right">
        <div v-if="!isMinDevice" class="r-title">
          {{ goodDetail.title }}
        </div>
        <div v-if="!isMinDevice" class="r-desc">
          {{ goodDetail.description }}
        </div>
        <div
          :style="`${
            isMinDevice
              ? 'display:flex;flex-wrap:wrap;justify-content: space-between'
              : ''
          }`">
          <div
            class="price-box flex-s"
            :class="{ 'mb-price-box': isMinDevice }">
            <div class="price">${{ goodDetail.marketprice }}</div>
            <div
              class="bottom-vip-price vip-price"
              :class="{ 'mb-bottom-vip-price': isMinDevice }">
              ${{ goodDetail.price }}
              <div class="img">
                <img
                  class="w100 h100"
                  src="@/assets/images/pc/pc-home-icon12.png"
                  alt=""
                  srcset="" />
              </div>
            </div>
          </div>

          <div
            v-if="goodDetail.category_text"
            class="common-row common-row1"
            :class="{ 'mb-common-row1': isMinDevice }">
            Type：{{ goodDetail.category_text }}
          </div>
        </div>
        <div
          v-if="isMinDevice"
          class="r-title"
          :class="{ 'mb-r-title': isMinDevice }">
          {{ goodDetail.title }}
        </div>
        <div
          v-if="goodDetail.flag && !isMinDevice"
          class="common-row common-row2"
          :class="{ 'mb-common-row2': isMinDevice }">
          label：{{ goodDetail.flag }}
        </div>
        <div
          :style="`${
            isMinDevice
              ? 'display:flex;flex-wrap:wrap;justify-content: space-between;align-items:center'
              : ''
          }`">
          <div
            class="common-row common-row3 flex-s"
            :class="{ 'mb-common-row3': isMinDevice }">
            stock: {{ goodDetail.stocks }}
            <div
              v-show="goodDetail.stocks < 10"
              class="stockstips point"
              :class="{ 'mb_stocktips': isMinDevice }"
              @click="stocktipsclick">
              Restock reminder
            </div>
          </div>
          <div
            v-show="goodDetail.stocks < 10"
            style="color: red"
            :class="{ 'mb_dofont': isMinDevice }">
            Insufficient inventory, need to wait for a merchant to restock, sure
            you need to keep buying?
          </div>
          <div class="btns flex-s">
            <div class="btns1 flex-b" :class="{ 'mb-btns1': isMinDevice }">
              <div @click="downOnClick" class="btn-down point"></div>
              <div class="btn-num flex-c">
                <input
                  class="common-input text-c"
                  type="text"
                  :class="{ 'mb-common-input': isMinDevice }"
                  v-model="goodNum"
                  @blur="blur" />
              </div>
              <div @click="upOnClick" class="btn-up point"></div>
            </div>
            <div
              v-if="!isMinDevice"
              @click="addToCarOnClick(goodDetail, true)"
              class="common-btn btns2 flex-c point">
              ADD TO CART
            </div>
            <div
              v-if="!isMinDevice"
              @click="checkOutOnClick(goodDetail)"
              class="common-btn btns3 flex-c point">
              CHECK OUT
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="common-line m-b-75"></div>
    <div v-if="isMinDevice" class="mb-btn-box flex-b">
      <div
        @click="addToCarOnClick(goodDetail, true)"
        class="common-btn btns2 flex-c point">
        ADD TO CART
      </div>
      <div
        @click="checkOutOnClick(goodDetail)"
        class="common-btn btns3 flex-c point">
        CHECK OUT
      </div>
    </div>
    <div
      v-if="goodDetail.content"
      class="goods-desc-box m-b-75"
      :class="{ 'mb-goods-desc-box': isMinDevice }">
      <div class="title">Product Description</div>
      <div v-html="goodDetail.content" class="desc"></div>
    </div>
    <div
      v-if="goodsList.length"
      class="goods-promotion"
      :class="{ 'mb-goods-desc-box mb-goods-promotion': isMinDevice }">
      <div class="title">Related Products</div>
      <div v-if="!isMinDevice" class="goods-list">
        <div @click="lastPageOnClick" class="left-icon flex-c">
          <div class="img" :class="{ 'rotate-img': page > 1 }"></div>
        </div>
        <div class="goodsboxlist">
          <div
            @click="bottomGoodOnClick(item)"
            v-for="item in goodsList.slice(0, 4)"
            :key="item.id"
            class="goodsbox point GSAPgoods-list">
            <div class="imgbox">
              <img class="w100 h100" :src="item.image" alt="" />
            </div>
            <div class="info">
              {{ item.title }}
            </div>
            <div class="price">
              <div class="bean">${{ item.marketprice }}</div>
              <div class="bottom-vip-price vipbean">
                ${{ item.price }}
                <div class="img">
                  <img
                    class="w100 h100"
                    src="@/assets/images/pc/pc-home-icon12.png"
                    alt=""
                    srcset="" />
                </div>
              </div>
            </div>
            <div class="btnbox flex-b point">
              <div @click.stop="addToCarOnClick(item)" class="check point">
                ADD TO CART
              </div>
            </div>
          </div>
        </div>
        <div @click="nextPageOnClick" class="right-icon flex-c">
          <div class="img" :class="{ 'rotate-img': TotalPages > page }"></div>
        </div>
      </div>
      <div v-if="isMinDevice" class="mb-goods-list">
        <van-swipe :show-indicators="true" :autoplay="300000000">
          <van-swipe-item
            class="flex-b"
            v-for="(vItem, index) in mbGoodsList"
            :key="index">
            <div v-for="item in vItem" class="mb-good-item" :key="item.id">
              <div
                @click="
                  $router.push({ path: '/MallDetail', query: { id: item.id } })
                "
                class="goodsbox point">
                <div class="imgbox">
                  <img class="w100 h100" :src="item.image" alt="" />
                </div>
                <div class="info elips-2">
                  {{ item.title }}
                </div>
                <div class="price flex-b">
                  <div class="bean">${{ item.marketprice }}</div>
                  <div class="bottom-vip-price vipbean">
                    ${{ item.price }}
                    <div class="img">
                      <img
                        class="w100 h100"
                        src="@/assets/images/pc/pc-home-icon12.png"
                        alt=""
                        srcset="" />
                    </div>
                  </div>
                </div>
                <div class="btnbox flex-b point">
                  <div @click.stop="addToCarOnClick(item)" class="check flex-c">
                    ADD TO CART
                  </div>
                </div>
                <div v-if="item.flag == 'hot'" class="float-img-new"></div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mallGoodsDetailApi,
  addCarApi,
  mallGoodsListApi,
  stock_noticeApi,
} from "@/network/api.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { gsap } from "gsap";
import _ from "lodash";
export default {
  data() {
    return {
      goodNum: 1,
      goodDetail: {},
      goodsList: [],
      page: 1,
      TotalPages: 1,
    };
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      // deep: true,
      // immediate: true,
    },
  },
  computed: {
    ...mapGetters(["isVip", "isLogin"]),
    ...mapState(["isMinDevice"]),
    mbGoodsList() {
      console.log(33331, _.chunk(this.goodsList, [2]));
      return _.chunk(this.goodsList, [2]);
    },
  },
  created() {
    this.init();
  },
  methods: {
    async stocktipsclick() {
      let params = {
        goods_id: this.$route.query.id,
      };
      const res = await stock_noticeApi(params);
      console.log(res);
      if (res.code == 1) {
        this.$message.success("Replenishment reminder successful");
      }
    },
    ...mapActions(["getCartList"]),
    ...mapMutations(["setShowTipPopup"]),
    init() {
      this.getGoodDetail();
    },
    bottomGoodOnClick(item) {
      this.$router.push({ path: "/MallDetail", query: { id: item.id } });
    },
    async lastPageOnClick() {
      if (this.page > 1) {
        this.page--;
        await this.getGoodsList();
        gsap.fromTo(
          ".GSAPgoods-list",
          {
            x: "-13.4rem",
          },
          {
            x: "0rem",
            duration: 0.3,
          }
        );
      }
    },
    async nextPageOnClick() {
      if (this.page < this.TotalPages) {
        this.page++;
        await this.getGoodsList();
        gsap.fromTo(
          ".GSAPgoods-list",
          {
            x: "13.4rem",
          },
          {
            x: "0rem",
            duration: 0.3,
          }
        );
      }
    },
    blur() {
      if (!this.goodNum) {
        this.goodNum = 1;
      }
    },
    async addToCarOnClick(item, isNum, showMsg = true) {
      if (!this.isLogin) {
        this.$message.error("Please login first");
        return false;
      }
      if (!this.isVip) {
        this.$message.error(
          "Please contact customer service to obtain VIP for free"
        );
        return false;
      }
      clearTimeout(this.Timer);
      const params = {
        goods_id: item.id,
        nums: isNum ? this.goodNum : 1,
      };
      try {
        const res = await addCarApi(params);
        this.getCartList();
        if (showMsg) {
          this.$message.success("Added successfully");
        }
        return true;
      } catch (error) {
        this.setShowTipPopup(true);
        return false;
      }
    },
    async checkOutOnClick(item) {
      const res = await this.addToCarOnClick(item, true, false);
      if (res) {
        this.$router.push("/car");
      }
    },
    downOnClick() {
      if (this.goodNum > 1) {
        this.goodNum--;
      }
    },
    upOnClick() {
      this.goodNum++;
      // if (this.goodNum < this.goodDetail?.stocks) {
      //   this.goodNum++;
      // } else {
      //   this.setShowTipPopup(true);
      // }
    },
    async getGoodDetail() {
      const params = {
        id: this.$route.query?.id,
      };
      const res = await mallGoodsDetailApi(params);
      this.goodDetail = res.data;
      setTimeout(() => {
        document.body.scrollIntoView(".view-mall-detail");
      }, 0);
      this.getGoodsList();
    },
    async getGoodsList() {
      if (this.goodDetail?.category_id) {
        const params = {
          category_id: this.goodDetail?.category_id,
          // sort: this.sort,
          type: "createtime",
          sort: "desc",
          page: this.page,
        };
        if (this.activeType) {
          params.category_id = this.activeType;
        }
        const res = await mallGoodsListApi(params);
        this.TotalPages = res.data.TotalPages;
        this.goodsList = res.data.Items;
      }
    },
    // async getHotList() {
    //   const params = {
    //     flag: this.goodDetail?.flag || "hot",
    //     page: this.page,
    //   };
    //   const res = await getHotListApi(params);
    //   this.goodsList = res.data.Items;
    //   this.TotalPages = res.data.TotalPages;
    // },
  },
};
</script>

<style lang="scss">
.view-mall-detail {
  padding: 0.9rem 2.23rem;
  .bread-cumb {
    margin-bottom: 0.21rem;
  }
  .header-img-title {
    display: flex;
    align-items: flex-start;
    .header-left {
      // width: 4.98rem;
      height: 5.21rem;
      flex-shrink: 0;
      margin-right: 0.38rem;
      // background: url("~@/assets/images/pc/pc-home-icon5.png") no-repeat;
      // background-size: 100% 100%;
    }
    .header-right {
      .r-title {
        font-size: 0.42rem;
        margin-bottom: 0.25rem;
        padding-top: 0.13rem;
      }
      .r-desc {
        font-size: 0.24rem;
        margin-bottom: 0.25rem;
        font-size: 0.24rem;
      }
      .price-box {
        font-size: 0.26rem;
        margin-bottom: 0.25rem;
        font-weight: 500;
        .price {
          margin-right: 0.75rem;
          font-size: 0.25rem;
          text-decoration: line-through;
        }
        .vip-price {
          font-size: 0.3rem;
        }
      }
      .mb-price-box {
        margin-right: 0.5rem;
      }
      .common-row {
        font-size: 0.24rem;
        margin-bottom: 0.16rem;
        .stockstips {
          margin-left: 1.5rem;
          color: #fff;
          background: #f6861e;
          border-radius: 0.04rem;
          font-size: 0.24rem;
          padding: 0.14rem 0.15rem;
          font-weight: 900;
          box-sizing: border-box;
        }
        .mb_stocktips {
          font-size: 0.6rem;
          border-radius: 0.12rem;
        }
      }
      .mb_dofont {
        font-size: 0.7rem;
      }
      .btns {
        margin-top: 0.3rem;
        .btns1 {
          margin-right: 0.24rem;
          width: 2.1rem;
          height: 0.54rem;
          flex-shrink: 0;
          background: url("~@/assets/images/Mall/mall-detail1.png") no-repeat;
          background-size: 100% 100%;
          .btn-down {
            width: 0.55rem;
            height: 100%;
            flex-shrink: 0;
          }
          .btn-num {
            flex-grow: 1;
            font-size: 0.24rem;
            height: 100%;
          }
          .btn-up {
            width: 0.55rem;
            height: 100%;
            flex-shrink: 0;
          }
        }
        .mb-btns1 {
          width: 5.9396rem;
          height: 1.5361rem;
          margin-top: -0.3rem;
          .btn-down {
            width: 1.5361rem;
          }
          .btn-up {
            width: 1.5361rem;
          }
        }
        .btns2 {
        }
        .btns3 {
        }
        .common-btn {
          color: #fff;
          background: #0076ff;
          border-radius: 0.04rem;
          font-size: 0.24rem;
          padding: 0.14rem 0.4rem;
          font-weight: 900;
          box-sizing: border-box;
          margin-right: 0.24rem;
          flex-shrink: 0;
        }
      }
    }
  }
  .mb-btn-box {
    margin-bottom: 1.0241rem;
    .common-btn {
      width: 7.6293rem;
      height: 1.9457rem;
      border-radius: 0.1024rem;
      font-size: 0.7168rem;
      color: #fff;
      background: rgba(0, 118, 255, 1);
      font-weight: bold;
    }
  }
  .mb-header-img-title {
    flex-wrap: wrap;
    .header-left {
      width: 100%;
      flex-shrink: 1;
      height: 15.0026rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        margin: 0 auto;
      }
    }
    .header-right {
      width: 100%;
      position: relative;
      .r-title {
        font-size: 0.7168rem;
      }
      .r-desc {
        font-size: 0.7168rem;
      }
      .price-box {
        .price {
          font-size: 0.9217rem;
          font-weight: 500;
          color: #333;
        }
        .bottom-vip-price {
          font-size: 1.0241rem;
        }
      }
      .mb-common-row1 {
        color: rgba(0, 118, 255, 1);
        font-size: 0.7168rem;
        // position: absolute;
        // right: 0;
        // top: 0;
      }
      .mb-r-title {
        font-size: 0.9217rem;
      }
      .mb-common-row2 {
      }
      .mb-common-row3 {
        font-size: 0.7168rem;
      }
    }
  }
  .goods-desc-box {
    .title {
      padding-right: 0.2rem;
      border-right: 2px solid #000;
      height: 0.25rem;
      font-size: 0.22rem;
      line-height: 0.22rem;
      width: fit-content;
      margin-bottom: 0.25rem;
    }
    .desc {
      font-size: 0.18rem;
      width: 100%;
      word-break: break-all;
      p {
        word-wrap: break-word;
        span {
          text-wrap: wrap !important;
          word-break: break-word !important;
        }
      }
    }
  }
  .mb-goods-desc-box {
    font-size: 0.7168rem;
    .title {
      font-size: 0.7168rem;
      height: 0.8705rem;
      display: flex;
      align-items: center;
      padding-right: 0.9rem;
    }

    .desc {
      font-size: 0.6144rem;
    }
  }
}
.mb-view-mall-detail {
  padding: 0.6144rem 1.2289rem;
}
</style>
