<template>
  <div class="view-home">
    <div class="home-banner" :class="{ 'mb-home-banner': isMinDevice }">
      <el-carousel
        v-if="!isMinDevice"
        :interval="2000"
        class="banner-box"
        height="6.12rem">
        <el-carousel-item
          class="banner-item point"
          v-for="item in bannerList"
          :key="item.id">
          <img
            v-if="!isMinDevice"
            class="banner-img w100 h100"
            :src="onlineUrl + item.image"
            alt=""
            @click="gotopage(item)" />
          <img v-if="isMinDevice" class="w100 h100" :src="item.image" alt="" />
        </el-carousel-item>
      </el-carousel>
      <van-swipe
        v-if="isMinDevice"
        style="height: 100%"
        class="my-swipe"
        :autoplay="5000"
        indicator-color="white">
        <van-swipe-item
          class="banner-item"
          v-for="item in bannerList"
          :key="item.id"
          @click="gotopage(item)">
          <img
            v-if="!isMinDevice"
            class="w100 h100"
            :src="onlineUrl + item.image"
            alt="" />
          <img v-if="isMinDevice" class="w100 h100" :src="item.image" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="home-content" :class="{ 'mb-home-content': isMinDevice }">
      <div class="content-desc flex-b">
        <div v-for="(item, index) in deskList" :key="index" class="desc-item">
          <img
            class="h100"
            :src="require(`@/assets/images/pc/${item.imgUrl}.png`)"
            alt=""
            srcset="" />
        </div>
      </div>
      <!-- <div class="content-about" :class="{ 'mb-content-about': isMinDevice }">
        <div class="about-left">
          <img
            class="h100"
            src="@/assets/images/pc/pc-home-about.png"
            alt=""
            srcset="" />
        </div>
        <div class="about-right">
          <div class="">
            Our company has just obtained authorization for the Microsoft SLP
            account key. We do not accept any Chinese people kidnapping or
            deceiving our products, and we will update our free account in the
            homepage news for key extraction and disclosure of the process
            instructions for opening a Microsoft account.
          </div>
        </div>
      </div> -->
      <!-- <div class="content-fuck-info">
        <img
          class="w100 h100"
          :src="onlineUrl + finalFuncImgs[0]"
          alt=""
          srcset="" />
      </div> -->
      <!-- <div class="content-line"></div> -->
      <div class="goods-lists">
        <div
          v-for="(item, index) in homeGoods"
          :key="index"
          class="goods-list-item">
          <div
            v-if="item.goods?.length && item.flag == 'index'"
            class="common-list-title"
            :class="{ 'mb-common-list-title': isMinDevice }">
            <div class="title-name" :class="{ 'mb-title-name': isMinDevice }">
              {{ item.name }}
            </div>
            <div
              class="title-line"
              :class="{ 'mb-title-line': isMinDevice }"></div>
          </div>

          <div
            v-if="item.goods?.length && item.flag == 'index'"
            class="list-box flex-b"
            :class="{ 'mb-list-box': isMinDevice }">
            <div
              @click="
                $router.push({
                  path: 'MallDetail',
                  query: { id: vItem.id },
                })
              "
              v-for="vItem in item.goods.slice(0, 4)"
              :key="vItem.id"
              class="list-item point">
              <div class="item-img" :class="{ 'mb-item-img': !isMinDevice }">
                <img
                  :class="{ 'animation-img': !isMinDevice }"
                  class="w100"
                  :src="vItem.image"
                  alt=""
                  srcset="" />
              </div>
              <div class="name elips-2">{{ vItem.title }}</div>
              <div class="item-bottom flex-b">
                <div class="bottom-price text-c">
                  <div>${{ vItem.marketprice }}</div>
                  <!-- <div v-if="!isMinDevice">price</div> -->
                </div>
                <div class="bottom-vip-price text-c">
                  <div>${{ vItem.price }}</div>
                  <!-- <div v-if="!isMinDevice">VIP</div> -->
                  <div class="img">
                    <img
                      class="w100 h100"
                      src="@/assets/images/pc/pc-home-icon12.png"
                      alt=""
                      srcset="" />
                  </div>
                </div>
                <div
                  class="bottom-add flex-c point"
                  @click.stop="addToCarOnClick(vItem)">
                  ADD TO CART
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="content-fuck-info2">
        <img
          class="w100 h100"
          :src="onlineUrl + finalFuncImgs[1]"
          alt=""
          srcset="" />
      </div> -->
      <!-- <div class="user-comment-box">
        <div
          class="common-list-title"
          :class="{ 'mb-common-list-title': isMinDevice }">
          <div class="title-name" :class="{ 'mb-title-name': isMinDevice }">
            USER EVALUATION
          </div>
          <div
            class="title-line"
            :class="{ 'mb-title-line': isMinDevice }"></div>
        </div>
        <div class="user-list flex-b" :class="{ 'mb-user-list': isMinDevice }">
          <div v-for="(item, index) in userList" :key="index" class="user-item">
            <div class="flex-c">
              <div class="header-img flex-c">
                <img
                  class="w100 h100"
                  :src="onlineUrl + item.imgUrl"
                  alt=""
                  srcset="" />
              </div>
            </div>
            <div v-if="!isMinDevice" class="header-name flex-c">
              {{ item.name }}
            </div>
            <div class="header-comment" :class="{ 'elips-2': !isMinDevice }">
              {{ item.comment }}
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div
      class="float-car-confirm"
      :class="{ 'mb-float-car-confirm': isMinDevice }"
      v-show="floatCarShow">
      <div class="close-btn" @click="floatCarShow = false"></div>
      <div class="top flex-s">
        <div class="top-left">
          <img class="w100 h100" :src="floatCarItem.image" alt="" srcset="" />
        </div>
        <div class="top-right">
          <div class="name">
            {{ floatCarItem.title }}
          </div>
          <div class="tip">Added to your shopping cart</div>
        </div>
      </div>
      <div class="bottom-btn flex-b">
        <div class="btn1 flex-c" @click="goToCarOnClick">VIEW CART</div>
        <div @click="goToCarWithBuyOnClick(floatCarItem)" class="btn1 flex-c">
          CHECK OUT
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { translate } from "@/utils/translate.js";
import { addCarApi, getBannerListApi } from "@/network/api.js";
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      floatCarShow: false,
      floatCarItem: {},
      // mallHomeGoodsList: [],
      bannerList: [],
      Timer: "",
    };
  },
  mounted() {
    // translate.changeLanguage("russian");
    translate.changeLanguage("english");
    this.$i18n.locale = "en";
    setTimeout(() => {
      this.handleScroll();
    }, 2000);
    // this.$i18n.locale = "ru-RU";
  },
  computed: {
    ...mapGetters(["isVip", "isLogin", "finalFuncImgs"]),
    ...mapState([
      "onlineUrl",
      "homeGoods",
      "userInfo",
      "isMinDevice",
      "carList",
    ]),
    deskList() {
      return [
        {
          imgUrl: "pc-home-icon1",
        },
        {
          imgUrl: "pc-home-icon2",
        },
        {
          imgUrl: "pc-home-icon3",
        },
      ];
    },
    userList() {
      return [
        {
          imgUrl: "/uploads/20231123/1fd5ccee51cd222b189069153313917e.png",
          name: "Edward",
          comment: "Thanks for ACE keys... Their service is really excellent.",
        },
        {
          imgUrl: "/uploads/20231123/31c17a094dc2b4ee71fe4608c3a9b8cf.png",
          name: "Fsasa",
          comment: "This website really shocked me.",
        },
        {
          imgUrl: "/uploads/20231123/6c59a1c438f22b1bbf9708dcdc899319.png",
          name: "Linda",
          comment: "I purchased 30x21pp binding, it's great!!!",
        },
      ];
    },
  },
  created() {
    this.init();
  },
  methods: {
    gotopage(item) {
      if (item.href) {
        this.$router.push(item.href);
      }
    },
    ...mapActions(["getCartList"]),
    ...mapMutations(["setShowTipPopup"]),
    isInViePortOfTwo(el) {
      const viewPortHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const viewPortWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const { top, right, bottom, left } = el.getBoundingClientRect();
      console.log(
        33333,
        el,
        top,
        right,
        bottom,
        left,
        viewPortHeight,
        viewPortWidth
      );
      return (
        top >= 0 &&
        left >= 0 &&
        bottom <= viewPortHeight + el.clientHeight * 1 &&
        right <= viewPortWidth
      );
    },
    async init() {
      window.addEventListener("scroll", this.handleScroll);
      await this.getBannerList();
    },
    handleScroll() {
      const animaitionImgs = document.querySelectorAll(".animation-img");
      animaitionImgs.forEach((el, index) => {
        if (this.isInViePortOfTwo(el)) {
          el.classList.add("animation-img-show");
          el.style.setProperty("--delay", `${index * 0.1}s`);
        } else {
          // el.classList.remove("animation-img-show");
        }
      });
    },
    goToCarOnClick() {
      if (!this.carList?.length) {
        this.$message.error("The shopping cart is empty");
        return false;
      }
      this.$router.push("/car");
    },
    goToCarWithBuyOnClick(item) {
      this.$router.push({
        path: "/car",
        query: {
          carType: 2,
          buyList: JSON.stringify([item.id]),
        },
      });
    },
    async getBannerList() {
      const res = await getBannerListApi();
      this.bannerList = res.data;
      if (this.isMinDevice) {
        this.bannerList = [
          {
            id: 1,
            image: require("@/assets/images/pc/mb-banner1.png"),
            href: res.data[0]?.href,
          },
          {
            id: 2,
            image: require("@/assets/images/pc/mb-banner2.png"),
            href: res.data[1]?.href,
          },
          {
            id: 3,
            image: require("@/assets/images/pc/mb-banner3.png"),
            href: res.data[2]?.href,
          },
          {
            id: 4,
            image: require("@/assets/images/pc/mb-banner4.png"),
            href: res.data[3]?.href,
          },
        ];
      }
    },
    async addToCarOnClick(item) {
      if (!this.isLogin) {
        this.$message.error("Please login first");
        return false;
      }
      if (!this.isVip) {
        this.$message.error(
          "Please contact customer service to obtain VIP for free"
        );
        return false;
      }
      clearTimeout(this.Timer);
      const params = {
        goods_id: item.id,
        nums: 1,
      };

      try {
        const res = await addCarApi(params);
        this.getCartList();
        this.floatCarShow = true;
        this.Timer = setTimeout(() => {
          this.floatCarShow = false;
        }, 3000);
        this.floatCarItem = item;
      } catch (error) {
        this.setShowTipPopup(true);
      }
    },
    // async getmallHomeGoodsList() {
    //   const res = await mallHomeGoodsListApi();
    //   this.mallHomeGoodsList = res.data;
    // },
  },
};
</script>

<style lang="scss">
.view-home {
  width: 100%;
  .common-list-title {
    height: 0.91rem;
    display: flex;
    align-content: space-between;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 0.6rem;
    .title-name {
      width: 100%;
      text-align: center;
      font-size: 0.32rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
    }

    .title-line {
      width: 0.59rem;
      height: 0.08rem;
      background: #003879;
    }
  }
  .mb-common-list-title {
    height: 1.7409rem;
    .mb-title-name {
      font-size: 0.7168rem;
    }
    .mb-title-line {
      width: 2.2017rem;
      height: 0.1024rem;
      // margin-top: 1rem;
    }
  }
  .home-banner {
    height: 6.12rem;
    margin-bottom: 0.78rem;
    .banner-box {
      .banner-item {
        .banner-img {
          transform: scale(1);
          transition: all 1s;
        }
        .banner-img:hover {
          transform: scale(1.08);
          transition: all 1s;
        }
      }
      .el-carousel__button {
        width: 0.15rem;
        height: 0.15rem;
        border-radius: 50%;
        background-color: rgba(0, 56, 121, 1);
        opacity: 1;
      }
      .el-carousel__indicator.is-active button {
        opacity: 1;
        background-color: #fff;
      }
    }
  }
  .mb-home-banner {
    margin-bottom: 1.0241rem;
  }
  .home-content {
    padding: 0 2.22rem;
    box-sizing: border-box;
    .content-desc {
      margin-bottom: 1.1rem;
      .desc-item {
        height: 0.68rem;
      }
    }
    .content-about {
      display: flex;
      align-items: flex-end;
      margin-bottom: 0.75rem;
      .about-left {
        // width: 2.6rem;
        height: 1.68rem;
        flex-shrink: 0;
        margin-right: 0.42rem;
      }
      .about-right {
        font-size: 0.18rem;
        padding-bottom: 0.22rem;
      }
    }
    .mb-content-about {
      align-items: center;
      .about-left {
        // width: 5.2739rem;
        height: 2.509rem;
      }
      .about-right {
        font-size: 0.4096rem;
      }
    }
    .content-fuck-info {
      height: 3.88rem;
      margin-bottom: 0.75rem;
    }
    .content-fuck-info2 {
      height: 3.02rem;
      margin-bottom: 0.75rem;
    }
    .content-line {
      border-bottom: 0.02rem solid #244b78;
      margin-bottom: 0.75rem;
    }
    .goods-lists {
      margin-bottom: 0.75rem;
      .goods-list-item {
        margin-bottom: 0.75rem;
        .list-box {
          display: flex;
          align-items: center;
          justify-content: start;
          flex-wrap: wrap;
          .list-item {
            width: 25%;
            flex-shrink: 0;
            padding: 0 0.3rem;
            box-sizing: border-box;
            margin-bottom: 0.75rem;
            .item-img {
              width: 100%;
              height: 3.96rem;
              margin-bottom: 0.24rem;
              position: relative;
              @keyframes move {
                from {
                  transform: translateY(0.5rem);
                }
                to {
                  transform: translateY(0rem);
                }
              }
              .animation-img {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 99;
                transform: translateY(0.5rem);
                // transition: move 0.4s;
                // animation: move 1s forwards;
              }
              .animation-img-show {
                // --delay:2;
                animation: move 0.8s cubic-bezier(0.56, 0.09, 0.49, 2.77)
                  forwards var(--delay);
                // transform: translateY(0rem);
                // transition: move 0.4s;
              }
            }

            .name {
              margin-bottom: 0.31rem;
              font-size: 0.18rem;
            }
            .item-bottom {
              .bottom-price {
                text-decoration: line-through;
              }
              .bottom-add {
                width: 1.56rem;
                height: 0.54rem;
                flex-shrink: 0;
                background: #ffffff;
                box-shadow: 0px 0.04rem 0.04rem 0px rgba(0, 0, 0, 0.1);
                border-radius: 0.04rem;
                border: 1px solid rgba(0, 0, 0, 0.1);
                font-size: 0.16rem;
                font-weight: 900;
              }
              .bottom-add:hover {
                background: #5ba7ff;
                color: #fff;
              }
            }
          }
        }
        .list-box::after {
          content: "";
          width: 100%;
        }
        .mb-list-box {
          .list-item {
            .item-img {
              height: unset;
            }
            .name {
              margin-bottom: 0.31rem;
              font-size: 0.4096rem;
              min-height: 1rem;
            }
            .item-bottom {
              flex-wrap: wrap;
              .bottom-price {
                font-size: 0.512rem;
              }
              .bottom-vip-price {
                font-size: 0.512rem;
                .img {
                  width: 1.1777rem;
                  height: 1.1777rem;
                  top: -0.5rem;
                  right: -0.5rem;
                }
              }
              .bottom-add {
                // width: 100%;
                width: 100%;
                height: 1.2289rem;
                flex-shrink: 0;
                font-size: 0.4096rem;
                background: #ffffff;
                box-shadow: 0px 0.2048rem 0.2048rem 0px rgba(0, 0, 0, 0.1);
                border-radius: 0.2048rem;
                border: 0.0512rem solid rgba(0, 0, 0, 0.1);
                margin-top: 0.512rem;
              }
            }
          }
        }
      }
    }
    .user-comment-box {
      margin-bottom: 1.4rem;
      .user-list {
        width: 100%;
        padding: 0 0rem;
        box-sizing: border-box;
        .user-item {
          width: 4.11rem;
          padding: 0.24rem 0.24rem 0.2rem 0.24rem;
          box-sizing: border-box;
          box-shadow: 0px 0.04rem 0.12rem 0px rgba(0, 0, 0, 0.1);
          border-radius: 0.06rem;
          border: 1px solid rgba(0, 0, 0, 0.1);
          .header-img {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 18px;
          }
          .header-name {
            font-size: 0.18rem;
            margin-bottom: 0.15rem;
            font-weight: bold;
          }
          .header-comment {
            height: 0.4rem;
            // overflow: hidden;
            // display: flex;
            // align-items: flex-start;
          }
        }
      }
      .mb-user-list {
        flex-wrap: wrap;
        .user-item {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0.5632rem 1.0241rem;
          margin-bottom: 0.6144rem;
          .header-img {
            width: 1.7409rem;
            height: 1.7409rem;
            margin-bottom: 0;
            margin-right: 0.512rem;
            flex-shrink: 0;
          }
          .header-comment {
            font-size: 0.512rem;
            height: unset;
          }
        }
      }
    }
  }
  .mb-home-content {
    padding: 0 1.0241rem;
    .content-desc {
      .desc-item {
        height: 0.7332rem;
      }
    }
  }
}
</style>
