<template>
  <div class="comp-header">
    <div v-if="!isMinDevice" class="home-header1">
      <div @click="$router.push('/')" class="h-header1-logo point">
        <img
          class="w100"
          src="@/assets/images/pc/pc-home-icon9.png"
          alt=""
          srcset="" />
      </div>
      <div class="h-header1-right">
        <div class="btn flex-c btn1" @click="gonew">Service</div>
        <a
          href="mailto:ACEsoftKeys@yeah.net"
          target="_blank"
          class="btn flex-c btn2"
          style="color: #fff"
          >Email</a
        >
        <div @click="JoinUsOnClick" class="btn flex-c btn3">Join Us</div>
      </div>
    </div>
    <div v-if="!isMinDevice" class="home-header2 flex-b">
      <div class="h-header2-left flex-s">
        <template v-if="homeGoods.length">
          <div
            @click="tabItemOnClick(item)"
            @mouseenter="dhFun"
            v-for="item in navList"
            :key="item.id"
            style=""
            class="h-header2-item point"
            :class="{ 'h-header2-item-active': activeItemId === item.id }">
            <div style="opacity: 0">{{ item.title }}</div>
            <div class="title">
              {{ item.title }}
            </div>
            <div v-if="item.children.length" class="float-div GSAPheight">
              <div
                v-for="vItem in item.children"
                :key="vItem.id"
                class="float-item flex-c"
                @click.stop="tabItemOnClick(vItem)">
                <div class="icon-img" v-if="vItem.icon">
                  <img class="w100 h100" :src="onlineUrl + vItem.icon" alt="" />
                </div>
                <div>{{ vItem.title }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="h-header2-right flex-b">
        <div
          @mouseenter="mouseenter1"
          @mouseleave="mouseleave1"
          class="right-item right-item1 point">
          <div
            @mouseenter="showCarFloat = true"
            @mouseleave="showCarFloat = false"
            v-show="showCarFloat && carList.length"
            class="float-right-item1">
            <div
              style="
                width: 100%;
                min-height: 0.295rem;
                opacity: 0;
                background: red;
              "></div>
            <div class="user-info GSAPheight">
              <div class="good-list">
                <div
                  v-for="item in carList"
                  :key="item.id"
                  class="good-item flex-s">
                  <div class="img">
                    <img class="h100" :src="item?.goods?.image" alt="" />
                  </div>
                  <div class="right">
                    <div class="title">
                      {{ item?.goods?.title }}
                    </div>
                    <div class="price-box flex-b">
                      <div class="price" v-if="isVip">
                        ${{ item?.goods?.price }}
                      </div>
                      <div class="price" v-else>
                        ${{ item?.goods?.marketprice }}
                      </div>
                      <div class="num">x {{ item?.nums }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bottom-box">
                <div class="header flex-b">
                  <div class="name">SUBTOTAL：</div>
                  <div class="price">$ {{ carTotalPrice }}</div>
                </div>
                <div class="btn-box flex-b">
                  <div @click="goToCarOnClick" class="btn1 flex-c">
                    VIEW CART
                  </div>
                  <div @click="goToCarWithBuyOnClick" class="btn1 flex-c">
                    CHECKOUT
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          @click="
            $router.push({
              path: '/User',
              query: {
                TabIndex: 1,
              },
            })
          "
          v-if="isLogin"
          @mouseenter="mouseenter2"
          @mouseleave="mouseleave2"
          class="right-item right-item2 point">
          <!-- <img class="w100 h100 img" :src="userInfo.avatar" alt="" srcset="" /> -->
          <div
            @mouseenter="showUserInfoFloat = true"
            @mouseleave="showUserInfoFloat = false"
            v-show="showUserInfoFloat"
            class="float-right-item2">
            <div style="width: 100%; min-height: 0.295rem; opacity: 0"></div>
            <div class="user-info GSAPheight">
              <div class="user-info-top flex-s">
                <div class="img">
                  <img
                    class="w100 h100"
                    :src="onlineUrl + userInfo.avatar"
                    alt=""
                    srcset="" />
                </div>
                <div class="info">
                  <div class="name">{{ userInfo.nickname }}</div>
                  <div class="email">{{ userInfo.email }}</div>
                </div>
              </div>
              <div class="user-info-center flex-b">
                <!-- <div class="div1">integral:{{ userInfo?.score || 0 }}</div> -->
                <div class="div1">balance:{{ userInfo.money || 0 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="login-text">
          <span class="point" @click="loginOnClick">Sign in</span
          ><span> / </span
          ><span class="point" @click="rsgOnClick">register</span>
        </div>
      </div>
    </div>
    <div v-if="isMinDevice" class="mb-home-header2 flex-b">
      <div @click="$router.push('/')" class="mb-h-header1-logo">
        <img
          class="w100"
          src="@/assets/images/pc/pc-home-icon9.png"
          alt=""
          srcset="" />
      </div>
      <div class="mb-right-icon-list flex-b">
        <van-badge :dot="carList.length > 0">
          <div @click="goToCarOnClick" class="icon icon1">
            <img
              class="h100"
              src="@/assets/images/pc/mb-home-icon1.png"
              alt=""
              srcset="" />
          </div>
        </van-badge>
        <!-- <div class="icon icon2">
          <img class="h100" src="@/assets/images/pc/mb-home-icon2.png" alt="" srcset="" />
        </div> -->
        <div @click="showMbMeanShowOnClick" class="icon icon3">
          <img
            class="h100"
            src="@/assets/images/pc/mb-home-icon3.png"
            alt=""
            srcset="" />
        </div>
      </div>
    </div>
    <van-overlay :show="rsgOverlayShow">
      <div class="header-overlay-common fixed-c">
        <div @click="rsgOverlayShow = false" class="close-btn point"></div>
        <div class="logo-box flex-c">
          <div class="logo">
            <img
              class="w100"
              src="@/assets/images/pc/pc-home-icon9.png"
              alt=""
              srcset="" />
          </div>
        </div>
        <div class="input-item">
          <div class="title">
            Username
            <span style="color: red; vertical-align: sub"> *</span>
          </div>
          <div class="input-box">
            <el-input
              v-model="rsgFormData.username"
              placeholder="input"></el-input>
          </div>
        </div>
        <div class="input-item">
          <div class="title">
            Email
            <span style="color: red; vertical-align: sub"> *</span>
          </div>
          <div class="input-box">
            <el-input
              v-model="rsgFormData.email"
              placeholder="input"></el-input>
          </div>
        </div>
        <div class="input-item">
          <div class="title">
            Email verification code
            <span style="color: red; vertical-align: sub"> *</span>
          </div>
          <div class="input-box flex-s">
            <el-input
              v-model="rsgFormData.captcha"
              placeholder="input"></el-input>
            <div @click="sendEmailCode" class="send-btn flex-c point">
              {{ countdownTime > 0 ? `${countdownTime}S` : "Get Code " }}
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="title">
            Password
            <span style="color: red; vertical-align: sub"> *</span>
          </div>
          <div class="input-box">
            <el-input
              type="password"
              v-model="rsgFormData.password"
              placeholder="input"></el-input>
          </div>
        </div>
        <div class="input-item">
          <div class="title">
            Confirm password
            <span style="color: red; vertical-align: sub"> *</span>
          </div>
          <div class="input-box">
            <el-input
              type="password"
              v-model="rsgFormData.secondPassword"
              placeholder="input"></el-input>
          </div>
        </div>
        <div class="input-item">
          <div class="title">Authentication Code（Optional）</div>
          <div class="input-box">
            <el-input
              v-model="rsgFormData.authCode"
              placeholder="input"></el-input>
          </div>
        </div>
        <div class="tips">Certified members can enjoy preferential rates.</div>
        <div class="rsg-btn flex-c point" @click="userRegOnClick">SUBMIT</div>
      </div>
    </van-overlay>
    <van-overlay :show="loginOverlayShow && !isMinDevice">
      <div class="header-overlay-common fixed-c">
        <div @click="loginOverlayShow = false" class="close-btn point"></div>
        <div class="logo-box flex-c">
          <div class="logo">
            <img
              class="w100"
              src="@/assets/images/pc/pc-home-icon9.png"
              alt=""
              srcset="" />
          </div>
        </div>
        <div class="input-item">
          <div class="title">Email address</div>
          <div class="input-box">
            <el-input
              v-model="loginFromData.account"
              placeholder="input"></el-input>
          </div>
        </div>
        <div class="input-item">
          <div class="title">Password</div>
          <div class="input-box">
            <el-input
              type="password"
              v-model="loginFromData.password"
              placeholder="input"></el-input>
          </div>
        </div>
        <div class="bottom-tip flex-s point">
          <div @click="remChecked = !remChecked" class="img">
            <div v-show="remChecked" class="in-img"></div>
          </div>
          <div @click="remChecked = !remChecked" class="remember">
            Remember me
          </div>
          <div @click="forgotOnClick" class="forgot point">
            Forgot Password？
          </div>
        </div>
        <div class="rsg-btn flex-c point" @click="userLoginOnClick">LOGIN</div>
        <div
          class="rsg-btn flex-c point"
          @click="
            loginOverlayShow = false;
            rsgOverlayShow = true;
          ">
          REGISTER
        </div>
        <div class="rsg-btn flex-c point" @click="loginOverlayShow = false">
          VISITOR BROWSING
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="clickFuckInfoShow">
      <div class="fuck-info-box fixed-c">
        <div @click="closeOnClick" class="close-btn point"></div>
        <div class="logo-box flex-c">
          <div class="logo">
            <img
              class="w100"
              src="@/assets/images/pc/pc-home-icon9.png"
              alt=""
              srcset="" />
          </div>
        </div>
        <div class="fuck-info-center flex-s">
          <div class="c-left">
            <div class="input-item">
              <div class="title">Username</div>
              <div class="input-box">
                <el-input
                  v-model="formData.username"
                  placeholder="First & Last Name"></el-input>
              </div>
            </div>
            <div class="input-item">
              <div class="title">email</div>
              <div class="input-box">
                <el-input
                  v-model="formData.email"
                  placeholder="Email Address (required)"></el-input>
              </div>
            </div>
            <div class="input-item">
              <div class="title">feedback</div>
              <div class="input-box">
                <el-input
                  resize="none"
                  type="textarea"
                  v-model="formData.content"
                  placeholder="feedback"
                  rows="3"></el-input>
              </div>
            </div>
          </div>
          <div class="c-center"></div>
          <div class="c-right">
            Send your message and we will provide you with a VIP code to unlock
            23 permissions, including redeeming more keys by points. Our ACE key
            will provide a 100% encrypted key supply service and share profits
            with all VIPs.
          </div>
        </div>
        <div @click="submitOnClick" class="rsg-btn flex-c point">SUBMIT</div>
      </div>
    </van-overlay>
    <!-- <van-overlay :show="false">
      <div class="check-tip-box fixed-c">
        <div class="check-tip-box-top flex-c">Tips</div>
        <div class="check-tip-box-content" style="padding: 0.7rem 1rem; text-align: center">
          <div class="content-text">
            Contact customer service for cargo replenishment
          </div>
          <div class="content-btn-box flex-c">
            <div class="btn1 flex-c point" @click="setShowTipPopup(false)">
              OK
            </div>
          </div>
          <div class="close-btn point" @click="setShowTipPopup(false)">
            <img class="w100 h100" src="@/assets/images/pc/pc-home-icon10.png" alt="" srcset="" />
          </div>
        </div>
      </div>
    </van-overlay> -->
    <van-overlay @click="mbMeanShow = false" :show="mbMeanShow">
      <transition name="slide-right">
        <div @click.stop="" v-show="mbMeanShow" class="mb-right-mean">
          <div class="header-icon flex-b">
            <van-badge :dot="carList.length > 0">
              <div @click="goToCarOnClick" class="icon icon1">
                <img
                  class="w100"
                  src="@/assets/images/pc/mb-home-icon1.png"
                  alt=""
                  srcset="" />
              </div>
            </van-badge>
            <div @click="mbMeanShow = false" class="icon icon1">
              <img
                class="w100"
                src="@/assets/images/pc/mb-home-icon4.png"
                alt=""
                srcset="" />
            </div>
          </div>
          <div class="header-img flex-c">
            <div class="img">
              <img
                @click="
                  $router.push('/User');
                  mbMeanShow = false;
                "
                v-if="isLogin"
                class="w100 h100"
                :src="onlineUrl + userInfo.avatar"
                alt=""
                srcset="" />
              <img
                v-else
                class="w100 h100"
                src="@/assets/images/pc/mb-mean-nologin.png"
                alt=""
                srcset="" />
            </div>
          </div>
          <div class="header-name flex-c">
            <div v-if="isLogin" class="text">
              {{ userInfo.nickname }}
            </div>
            <div v-else class="text">
              <span @click="$router.push('Login')">Sign in</span>
              <span> / </span>
              <span @click="$router.push('Register')">Register</span>
            </div>
          </div>
          <div v-if="isLogin" class="integral-box flex-a">
            <!-- <div class="box">integral:{{ userInfo.score }}</div> -->
            <div class="box">balance:{{ userInfo.money }}</div>
          </div>
          <div class="mb-right-mean-box">
            <template v-if="homeGoods.length">
              <div
                v-for="item in navList"
                :key="item.id"
                style=""
                class="right-mean-item mean-item"
                :class="{ 'right-mean-item-active': activeItemId === item.id }">
                <!-- <div style="opacity: 0">{{ item.title }}</div> -->
                <div
                  @click="tabItemOnClick(item, !!item.children.length)"
                  class="title flex-b">
                  <div>{{ item.title }}</div>
                  <div
                    v-if="item.children?.length"
                    class="icon"
                    :class="{ 'rotate-90-icon': item.open }"></div>
                </div>
                <div class="MBGSAPheight">
                  <div
                    v-if="item.children.length && item.open"
                    class="mb-float-div">
                    <div
                      v-for="vItem in item.children"
                      :key="vItem.id"
                      class="float-item mean-item"
                      @click.stop="tabItemOnClick(vItem)">
                      <div class="icon-img" v-if="vItem.icon">
                        <!-- <img class="w100 h100" :src="onlineUrl + vItem.icon" alt="" /> -->
                      </div>
                      <div>{{ vItem.title }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </transition>
    </van-overlay>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import autoAnimate from "@formkit/auto-animate";
import {
  userRsgApi,
  sendEmailCodeApi,
  checkEmailCodeApi,
  userLoginApi,
  getNewdataApi,
  infoSubmitApi,
} from "@/network/api";
import { translate } from "@/utils/translate.js";
import { validateEmail } from "@/utils/common.js";
import { gsap } from "gsap";
import { encode, decode } from "js-base64";
import { throttle } from "lodash";
export default {
  methods: {
    ...mapMutations(["setShowTipPopup"]),
    forgotOnClick: throttle(function () {
      this.$message.warning("Please contact customer service");
    }, 3000),
    showMbMeanShowOnClick() {
      this.mbMeanShow = true;
      document.querySelectorAll(".MBGSAPheight").forEach((item) => {
        autoAnimate(item);
      });
    },
    closeOnClick() {
      this.setClickFuckInfoShow(false);
    },
    async submitOnClick() {
      const res = await infoSubmitApi(this.formData);
      this.$message.success(res.msg);
      this.setClickFuckInfoShow(false);
    },
    //点击获取新闻数据跳转详情页
    async gonew() {
      const res = await getNewdataApi(this.userInfo.id ? this.userInfo.id : "");
      if (res.code == 1) {
        let data = res.data.Items;
        this.$router.push({
          path: "/newDetail",
          query: { id: data[0].id, user_id: this.userInfo.id },
        });
      }
    },
    ...mapMutations([
      "setUserInfo",
      "setLanguage",
      "setToken",
      "setClickFuckInfoShow",
    ]),
    ...mapActions(["getCartList", "setTokenSync"]),
    init() {
      this.Timer2 = setTimeout(() => {
        if (!this.isLogin) {
          this.loginOverlayShow = true;
        }
      }, 3000);
      if (this.userInfo?.token) {
        this.getCartList();
      }
    },
    goToCarOnClick() {
      if (!this.carList?.length) {
        this.$message.error("The shopping cart is empty");
        return false;
      }
      this.mbMeanShow = false;
      this.$router.push("/car");
    },
    JoinUsOnClick() {
      this.setClickFuckInfoShow(true);
    },
    mouseenter1() {
      this.showCarFloat = true;
      this.dhFun();
    },
    mouseleave1() {
      this.showCarFloat = false;
    },
    mouseenter2() {
      this.showUserInfoFloat = true;
      this.dhFun();
    },
    mouseleave2() {
      this.showUserInfoFloat = false;
    },
    dhFun() {
      gsap.fromTo(
        ".GSAPheight",
        {
          height: "0px",
        },
        {
          height: "auto",
          duration: 0.3,
        }
      );
    },
    goToCarWithBuyOnClick() {
      this.$router.push({
        path: "/car",
        query: {
          carType: 2,
        },
      });
    },
    tabItemOnClick(item, isOpen = false) {
      if (isOpen) {
        item.open = !item.open;
        this.$forceUpdate();
      }
      if (item.title === "English") {
        translate.changeLanguage("english");
        this.$i18n.locale = "en";
        this.setLanguage("en");
        return false;
      }
      if (item.title === "Russian") {
        translate.changeLanguage("russian");
        this.$i18n.locale = "ru-RU";
        this.setLanguage("ru");
        return false;
      }
      if (item.parentId) {
        this.activeItemId = item.parentId;
      } else {
        if (!item.children.length) {
          this.activeItemId = item.id;
        }
      }
      if (item.path) {
        this.$router.push({
          path: item.path,
          query: {
            ...this.$router.query,
            ...(item.query || {}),
          },
        });
      }
      if (!isOpen) {
        this.mbMeanShow = false;
      }
    },
    loginOnClick() {
      if (this.Timer2) {
        clearTimeout(this.Timer2);
      }
      this.loginOverlayShow = true;
      const remUser = localStorage.getItem("rsmNo");
      if (remUser) {
        const parseRemUserArray = remUser.split("&");
        if (parseRemUserArray.length > 1) {
          this.loginFromData.account = decode(parseRemUserArray[0]);
          this.loginFromData.password = decode(parseRemUserArray[1]);
          this.remChecked = true;
        }
      }
    },
    rsgOnClick() {
      if (this.Timer2) {
        clearTimeout(this.Timer2);
      }
      this.rsgOverlayShow = true;
    },
    async userLoginOnClick() {
      let flag = true;
      Object.keys(this.loginFromData).forEach((key) => {
        if (!this.loginFromData[key]) {
          if (flag) {
            this.$message.error("Please fill in the complete information");
          }
          flag = false;
        }
      });
      if (!flag) {
        return false;
      }

      const res = await userLoginApi(this.loginFromData);
      if (this.remChecked) {
        const user = encode(`${this.loginFromData.account}`);
        const pass = encode(`${this.loginFromData.password}`);
        localStorage.setItem("rsmNo", `${user}&${pass}`);
      } else {
        localStorage.setItem("rsmNo", "");
      }
      await this.setTokenSync(res?.data?.userinfo?.token);
      this.$message.success(res.msg);
      this.loginOverlayShow = false;
    },
    async userRegOnClick() {
      if (!this.submitTimer) {
        return false;
      }
      this.submitTimer = false;
      setTimeout(() => {
        this.submitTimer = true;
      }, 3000);
      let flag = true;
      Object.keys(this.rsgFormData).forEach((key) => {
        if (key !== "authCode") {
          if (!this.rsgFormData[key]) {
            if (flag) {
              this.$message.error("Please fill in the complete information");
            }
            flag = false;
          }
        }
      });
      if (!flag) {
        return false;
      }
      if (this.rsgFormData.password !== this.rsgFormData.secondPassword) {
        this.$message.error("The two password entries are inconsistent");
        return false;
      }
      const isCheck = await this.checkEmailCode();
      if (!isCheck) {
        return false;
      }
      const params = this.rsgFormData;
      const res = await userRsgApi(params);
      // this.setToken(res?.data?.userinfo?.token);
      await this.setTokenSync(res?.data?.userinfo?.token);
      this.$message.success(res.msg);
      this.rsgOverlayShow = false;
    },
    async sendEmailCode() {
      if (this.countdownTime) {
        return false;
      }
      const email = this.rsgFormData.email;
      if (!validateEmail(email)) {
        this.$message.error("Email is not valid");
        return false;
      }
      const params = {
        email,
        event: "register",
      };
      if (this.Timer) {
        clearInterval(this.Timer);
      }
      const res = await sendEmailCodeApi(params);
      console.log(res);
      this.countdownTime = 60;
      this.Timer = setInterval(() => {
        if (this.countdownTime > 0) {
          this.countdownTime--;
        }
      }, 1000);
      this.$message.success(res.msg);
    },
    async checkEmailCode() {
      const params = {
        email: this.rsgFormData.email,
        event: "register",
        captcha: this.rsgFormData.captcha,
      };
      const res = await checkEmailCodeApi(params);
      return true;
    },
  },
  created() {
    this.init();
  },
  data() {
    return {
      // fuckInfoShow: false,
      remChecked: false,
      activeItemId: null,
      rsgOverlayShow: false,
      loginOverlayShow: false,
      showUserInfoFloat: false,
      showCarFloat: false,
      rsgFormData: {
        username: "",
        password: "",
        secondPassword: "",
        email: "",
        authCode: "",
        captcha: "",
      },
      loginFromData: {
        account: "",
        password: "",
      },
      show: false,
      keyframes: [
        { opacity: 1, transform: "rotate(0deg)" },
        { opacity: 1, transform: "rotate(180deg)" },
      ],
      countdownTime: 0,
      formData: {
        username: "",
        email: "",
        content: "",
      },
      Timer: null,
      Timer2: null,
      mbMeanShow: false,
      submitTimer: true,
    };
  },
  computed: {
    ...mapState([
      "isMinDevice",
      "userInfo",
      "homeGoods",
      "onlineUrl",
      "carList",
      "clickFuckInfoShow",
      "showTipPopup",
    ]),
    ...mapGetters(["isVip", "isLogin"]),
    carTotalPrice() {
      let totle = 0;
      this.carList.forEach((item) => {
        if (this.isVip) {
          totle += item?.goods?.price * item?.nums;
        } else {
          totle += item?.goods?.marketprice * item?.nums;
        }
      });
      return totle?.toFixed(1);
    },
    navList() {
      const tyleList = (this.homeGoods || []).map((item, index) => {
        return {
          parentId: 2,
          id: `2${index}`,
          title: item.name,
          path: "/Mall",
          query: {
            shopType: item.id,
          },
        };
      });
      return [
        {
          id: 1,
          parentId: null,
          title: "Home",
          path: "/",
          children: [],
        },
        {
          id: 2,
          title: "Shop",
          path: "/Mall",
          children: [] || tyleList,
        },
        // {
        //   id: 3,
        //   title: "News",
        //   path: "/New",
        //   children: [],
        // },
        {
          id: 4,
          title: "On-Line Checking",
          path: "/CheckKeys",
          children: [],
        },
        // {
        //   id: 5,
        //   title: "Point Shop",
        //   path: "/IntegralMall",
        //   children: [],
        // },
        {
          id: 6,
          title: "Reward",
          path: "/Roll",
          children: [],
        },
        // {
        //   id: 7,
        //   title: "Language",
        //   children: [
        //     {
        //       parentId: 7,
        //       id: 71,
        //       title: "English",
        //       icon: "/uploads/20231123/d89616b65f7de7f51158651557021e5c.png",
        //     },
        //     {
        //       parentId: 7,
        //       id: 72,
        //       title: "Russian",
        //       icon: "/uploads/20231122/5436b7724f6f265c6de7b4dc81d0692a.png",
        //     },
        //   ],
        // },
      ];
    },
  },
};
</script>

<style lang="scss">
.comp-header {
  .header-overlay1 {
    background: #fff;
    padding: 1rem;
  }

  .home-header1 {
    // opacity: 0.2;
    height: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.07rem 0 1.01rem;

    .left-mean-icon {
      width: 0.5rem;
      height: 0.5rem;
      background: green;
    }

    .h-header1-logo {
      width: 1.9rem;
      // height: 0.68rem;
      // background: #000;
      // @include pcurl("pc-home-icon9.png");
      flex-shrink: 0;
    }

    .h-header1-right {
      width: 7.51rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      font-weight: 600;

      .btn {
        width: 2.29rem;
        height: 0.34rem;
        background: linear-gradient(180deg, #278bff 0%, #004fac 100%);
        box-shadow: 0 0.04rem 0.06rem 0 rgba(0, 118, 255, 0.2);
        border-radius: 0.04rem;
        cursor: pointer;
      }
    }
  }

  .home-header2 {
    height: 0.9rem;
    padding: 0 1.01rem 0 1.01rem;
    background: #002550;

    .h-header2-left {
      height: 100%;

      .h-header2-item {
        margin-right: 0.8rem;
        color: #c1b4b4;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 0.14rem;
        font-weight: 100;
        position: relative;
        justify-content: center;
        box-sizing: border-box;

        .title {
          position: absolute;
          color: #fff;
          // font-size: 0.16rem;
        }

        .float-div {
          overflow: hidden;
          position: absolute;
          top: 0.9rem;
          left: 50%;
          opacity: 0;
          transform: translateX(-50%);
          height: 1px;
          min-width: 1rem;
          text-align: center;
          z-index: 99;
          box-sizing: border-box;

          .float-item {
            padding: 0.14rem 0.14rem;
            box-sizing: border-box;
            min-width: 1.71rem;
            color: #fff;
            font-size: 0.16rem;
            font-weight: 500;
            background: rgb(74, 103, 151, 0.75);
            height: 0;
            padding: 0;

            .icon-img {
              width: 0.23rem;
              height: 0.23rem;
              margin-right: 0.18rem;
            }
          }

          .float-item:hover {
            background: #5589c5;
            font-size: 0.16rem;
            font-weight: 500;
          }
        }
      }

      .h-header2-item:hover {
        .title {
          font-weight: bold;
          color: #fff;
        }

        .float-div {
          opacity: 1;

          .float-item {
            height: auto;
            padding: 0.14rem 0.14rem;
          }
        }
      }

      .h-header2-item-active {
        border-bottom: 0.03rem solid #fff;

        .title {
          font-weight: 500;
          color: #fff;
        }
      }
    }

    .h-header2-right {
      .right-item {
        width: 0.32rem;
        height: 0.32rem;
      }

      .right-item1 {
        @include pcurl("pc-header-icon1.png");
        position: relative;
        margin-right: 0.56rem;

        .float-right-item1 {
          // background: red;
          position: absolute;
          top: 0.31rem;
          right: 0rem;
          width: 3.07rem;
          padding: 0;
          z-index: 100;

          .user-info {
            background: rgba(0, 56, 121, 0.75);
            font-size: 0.14rem;
            color: #fff;
            overflow: hidden;

            .good-list {
              max-height: 4rem;
              overflow-y: auto;
              padding: 0.12rem;

              .good-item {
                display: flex;
                align-content: space-between;
                margin-bottom: 0.13rem;

                .img {
                  // width: 0.74rem;
                  height: 0.89rem;
                  // @include pcurl("pc-header-icon2.png");
                  flex-shrink: 0;
                  margin-right: 0.1rem;
                }

                .right {
                  .title {
                    margin-bottom: 0.14rem;
                  }
                }
              }
            }

            .bottom-box {
              padding: 0.08rem 0.12rem;
              box-sizing: border-box;
              background: rgba(0, 56, 121, 1);

              .header {
                margin-bottom: 0.08rem;
              }

              .btn-box {
                .btn1 {
                  width: 1.3rem;
                  height: 0.38rem;
                  background: rgba(0, 118, 255, 1);
                }
              }
            }
          }
        }
      }

      .right-item2 {
        position: relative;

        .img {
          border-radius: 50%;
          overflow: hidden;
        }

        @include pcurl("pc-header-icon2.png");

        .float-right-item2 {
          // background: #fff;
          position: absolute;
          top: 0.31rem;
          right: 0rem;
          width: 2.4rem;
          padding: 0;
          z-index: 100;

          .user-info {
            width: 100%;
            background: rgba(0, 56, 121, 0.75);
            padding: 0.18rem;
            color: #fff;
            font-size: 0.16rem;
            overflow: hidden;

            .user-info-top {
              margin-bottom: 0.25rem;

              .img {
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                overflow: hidden;
                // @include pcurl("pc-header-icon2.png");
                flex-shrink: 0;
                margin-right: 0.24rem;
              }

              .info {
                height: 0.42rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .name {
                }

                .email {
                }
              }
            }

            .user-info-center {
              flex-wrap: wrap;

              .div1 {
                margin: 0.03rem 0;
              }
            }
          }
        }
      }

      .login-text {
        color: #fff;
      }
    }
  }
  .mb-home-header2 {
    height: 2.2529rem;
    padding: 0 1.01rem 0 1.01rem;
    background: #fff;
    padding: 0 1.0241rem;
    .mb-h-header1-logo {
      width: 5.8372rem;
    }
    .mb-right-icon-list {
      .icon {
        height: 1.0241rem;
      }
      .icon1 {
      }
      .icon2 {
        margin: 0 1.4337rem;
      }
      .icon3 {
        margin-left: 1.4337rem;
      }
    }
  }

  .header-overlay-common {
    width: 7.8rem;
    background: #fff;
    box-shadow: #eee 0rem 0rem 0.02rem 0.02rem;
    border-radius: 0.12rem;
    padding: 0.1rem 0.51rem 0.44rem;
    position: relative;

    .close-btn {
      position: absolute;
      right: 0.2rem;
      top: 0.2rem;
      width: 0.25rem;
      height: 0.25rem;

      @include pcurl("pc-home-icon10.png");
    }

    .logo-box {
      margin-bottom: 0.55rem;

      .logo {
        width: 3.68rem;
      }
    }

    .input-item {
      margin-bottom: 0.12rem;

      .title {
        font-size: 0.28rem;
        margin-bottom: 0.12rem;
        color: #939393;
      }

      .input-box {
        width: 100%;
        height: 0.48rem;

        .send-btn {
          width: 2.04rem;
          height: 0.48rem;
          background: rgba(0, 56, 121, 1);
          border-radius: 0.04rem;
          font-size: 0.28rem;
          color: #fff;
          // font-weight: 600;
          flex-shrink: 0;
        }
      }
    }

    .tips {
      font-size: 0.2rem;
      color: #888;
      margin-bottom: 0.35rem;
    }

    .bottom-tip {
      margin-bottom: 0.28rem;

      .img {
        width: 0.24rem;
        height: 0.24rem;
        border: 1px solid #e5e5e5;
        margin-right: 0.13rem;
        padding: 0.02rem;
        box-sizing: border-box;

        // @include pcurl("pc-home-icon11.png");
        .in-img {
          width: 100%;
          height: 100%;
          background: rgba(136, 136, 136, 1);
        }
      }

      .remember {
        font-size: 0.2rem;
        color: #888;
      }

      .forgot {
        font-size: 0.22rem;
        margin-left: auto;
      }
    }

    .rsg-btn {
      width: 100%;
      height: 0.71rem;
      background: rgba(0, 56, 121, 1);
      color: #fff;
      font-size: 0.3rem;
      margin-bottom: 0.12rem;
    }
  }
  .fuck-info-box {
    width: 12.61rem;
    background: #fff;
    padding: 0.11rem 0.51rem 0.44rem;
    position: relative;
    .logo-box {
      margin-bottom: 0.55rem;
      .logo {
        width: 3.68rem;
        // height: 1.2rem;
        // @include pcurl("pc-home-icon9.png");
      }
    }
    .fuck-info-center {
      height: 2.6rem;
      margin-bottom: 0.41rem;
      .c-left {
        margin-right: 0.55rem;
        .input-item {
          display: flex;
          align-items: flex-start;
          margin-bottom: 0.39rem;
          .title {
            font-size: 0.28rem;
            color: #939393;
            width: 1.88rem;
            padding-top: 0.03rem;
          }
          .input-box {
            width: 4.34rem;
            height: 0.5rem;
          }
        }
      }
      .c-center {
        border-right: 1px solid rgba(0, 56, 121, 0.5);
        margin-right: 0.32rem;
        height: 100%;
      }
      .c-right {
        font-size: 0.28rem;
        color: #797979;
        height: 100%;
      }
    }
    .rsg-btn {
      width: 70%;
      height: 0.71rem;
      margin: 0 auto;
      background: rgba(0, 56, 121, 1);
      color: #fff;
      font-size: 0.3rem;
    }
  }

  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: transform 0.5s;
  }
  .slide-right-enter,
  .slide-right-leave-to {
    transform: translateX(100%);
  }
  .mb-right-mean {
    width: 60.8vw;
    height: 100%;
    background: #fff;
    position: absolute;
    right: 0;
    font-size: 0.7168rem;
    padding: 0.7168rem 1.0241rem;
    box-sizing: border-box;
    .header-icon {
      margin-bottom: 1.8945rem;
      .icon {
        width: 0.6656rem;
        height: 0.6656rem;
      }
    }
    .header-img {
      margin-bottom: 0.6144rem;
      .img {
        width: 3.3794rem;
        height: 3.3794rem;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    .header-name {
      margin-bottom: 0.5289rem;
      font-size: 0.7168rem;
      .text {
      }
    }
    .integral-box {
      margin-bottom: 1.2rem;
      .box {
        // color: rgb(170, 170, 170);
      }
    }
    .mb-right-mean-box {
      color: rgba(170, 170, 170, 1);
      font-size: 0.7168rem;
      .mean-item {
        margin-bottom: 0.6289rem;
        .title {
          margin-bottom: 0.6289rem;
          .icon {
            width: 0;
            height: 0;
            border: 0.256rem solid transparent;
            border-top-color: rgba(170, 170, 170, 1);
            transform: translateY(0.14rem);
            transform: rotate(-90deg);
          }
          .rotate-90-icon {
            transform: rotate(0deg);
          }
        }
        .MBGSAPheight {
          // overflow: hidden;
          // height: 0px;
        }
        .mb-float-div {
          // transition: opacity 5s;
        }
      }
      .right-mean-item-active {
        color: rgba(76, 169, 255, 1);
        .mb-float-div {
          color: rgba(170, 170, 170, 1);
        }
      }
    }
  }
}
</style>
