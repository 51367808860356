<template>
  <div
    class="view-integral-mall"
    :class="{ 'mb-view-integral-mall': isMinDevice }">
    <div class="header-fuck-info m-b-75">
      <img class="w100" :src="onlineUrl + finalFuncImgs[3]" alt="" />
    </div>
    <div
      class="inte-mall-content"
      :class="{ 'mb-inte-mall-content': isMinDevice }">
      <div class="content-top flex-s">
        <div
          class="content-top-left flex-s"
          :class="{ 'mb-content-top-left': isMinDevice }">
          <div
            @click="changeType({ id: 'all' })"
            class="type-item1 flex-c point">
            All
          </div>
          <div
            @click.stop="changeType(item)"
            v-for="item in pointGoodsTypeList"
            :key="item.id"
            class="type-item type-item2 flex-c"
            :class="{ 'active-item': item.id === activeType }">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="content-center" :class="{ 'mb-content-center': isMinDevice }">
        <div class="center-top flex-s">
          <div v-if="!isMinDevice" style="margin-right: 0.1rem">Sortord</div>
          <div class="">
            <Cselect
              v-if="!isMinDevice"
              :width="3.2"
              :selectList="selectList"
              v-model="selectItem" />
            <Cselect
              v-if="isMinDevice"
              :width="8.1413"
              :height="1.1265"
              :selectList="selectList"
              v-model="selectItem" />
          </div>
        </div>
        <div
          @click="goToDetail"
          class="center-main flex-b"
          :class="{ 'mb-center-main': isMinDevice }">
          <div class="list-item" v-for="item in goodsList" :key="item.id">
            <div class="img-box flex-c">
              <div class="img">
                <img
                  class="h100"
                  :src="onlineUrl + item.image"
                  alt=""
                  srcset="" />
              </div>
            </div>
            <div class="title">
              {{ item.title }}
            </div>
            <div class="integral text-c flex-b">
              <!-- <div class="">integral:{{ item.score }}</div> -->
              <!-- <div class="">stock:{{ item.stocks }}</div> -->
              <div class="num-box flex-s">
                <div @click.stop="carDownOnClick(item)" class="btn-down"></div>
                <div class="btn-num btn-nums flex-c">
                  <input
                    class="common-input flex-c"
                    :class="{ 'mb-common-input': isMinDevice }"
                    type="text"
                    v-model="item.buyNum"
                    @blur="carChangeOnClick($event, item)" />
                </div>
                <div @click.stop="carUpOnClick(item)" class="btn-up"></div>
              </div>
            </div>
            <div class="total-integral flex-e">
              <!-- Total integral：{{ item.buyNum * item.score }} -->
            </div>
            <div @click.stop="pointBuyOnClick(item)" class="btn flex-c point">
              Exchange
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  pointTypesApi,
  pointGoodsListApi,
  pointBuyApi,
} from "@/network/api.js";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      pointGoodsTypeList: [],
      selectItem: "",
      activeType: "",
      type: "score",
      sort: "asc",
      goodsList: [],
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters(["isVip", "finalFuncImgs", "isLogin"]),
    ...mapState(["onlineUrl", "isMinDevice"]),
    selectList() {
      return [
        {
          label: "Price from low to high",
          value: 1,
        },
        {
          label: "Price from high to low",
          value: 2,
        },
        {
          label: "Listing time from low to high",
          value: 3,
        },
        {
          label: "Listing time from high to low",
          value: 4,
        },
      ];
    },
  },
  watch: {
    selectItem: {
      handler(newVal) {
        switch (newVal) {
          case 1:
            this.type = "score";
            this.sort = "asc";
            break;

          case 2:
            this.type = "score";
            this.sort = "desc";
            break;

          case 3:
            this.type = "createtime";
            this.sort = "asc";
            break;

          case 4:
            this.type = "createtime";
            this.sort = "desc";
            break;

          default:
            break;
        }
        this.getPointGoodsList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["ReFreshUserInfo"]),
    async init() {
      await this.getGoodsType();
      this.getPointGoodsList();
    },
    async carDownOnClick(item) {
      if (item.buyNum > 1) {
        item.buyNum--;
      }
    },
    async carChangeOnClick(e, item) {
      item.buyNum = e.target.value;
      if (!item.buyNum || item.buyNum == "0") {
        item.buyNum = 1;
      }
    },
    async carUpOnClick(item) {
      item.buyNum++;
    },
    goToDetail() {
      if (!this.isLogin) {
        this.$message.error("Please login first");
        return false;
      }
      // this.$message.error('Please click the Exchange button to redeem');
    },
    async pointBuyOnClick(item) {
      if (!this.isLogin) {
        this.$message.error("Please login first");
        return false;
      }
      const params = {
        exchange_id: item.id,
        nums: item.buyNum,
      };
      const res = await pointBuyApi(params);
      this.$message.success(res.msg);
      this.ReFreshUserInfo();
    },
    changeType(item) {
      if (item.id === "all") {
        this.activeType = "";
        this.getPointGoodsList();
        return false;
      }
      this.activeType = item.id;
      this.getPointGoodsList();
    },
    async getGoodsType() {
      const res = await pointTypesApi();
      this.pointGoodsTypeList = res.data;
    },
    async getPointGoodsList() {
      const params = {
        type: this.type,
        sort: this.sort,
      };
      if (this.activeType) {
        params.category_id = this.activeType;
      }
      const res = await pointGoodsListApi(params);
      this.goodsList = res.data.Items.map((item) => {
        return {
          buyNum: 1,
          ...item,
        };
      });
    },
  },
};
</script>

<style lang="scss">
.view-integral-mall {
  padding: 0.9rem 2.24rem;
  .header-fuck-info {
    width: 100%;
    // height: 0.8rem;
    // background: rgba(217, 217, 217, 1);
  }
  .inte-mall-content {
    .content-top {
      margin-bottom: 0.25rem;
      .content-top-left {
        border: 1px solid rgba(0, 93, 179, 0.1);
        box-shadow: #eee 0.02rem 0.02rem 0.1rem 0.02rem;
        padding: 0.08rem;
        .type-item1 {
          width: 1.74rem;
          height: 0.47rem;
          border-radius: 0.04rem;
          background: rgba(0, 56, 121, 1);
          font-size: 0.18rem;
          font-weight: 600;
          color: #fff;
        }

        .type-item {
          // width: 0.89rem;
          height: 0.46rem;
          border: 1px solid rgba(0, 56, 121, 0.1);
          cursor: pointer;
          padding: 0 0.3rem;
          white-space: nowrap;
        }
        .type-item:hover {
          background: rgba(0, 56, 121, 0.1);
        }
        .active-item {
          background: rgba(0, 56, 121, 0.1);
        }
      }
      .mb-content-top-left {
        padding: 0.3072rem;
        .type-item1 {
          width: 4.4547rem;
          height: 1.1265rem;
          font-size: 0.512rem;
        }
        .type-item {
          height: 1.1265rem;
          padding: 0 1.5rem;
          font-size: 0.512rem;
        }
      }
    }
    .content-center {
      padding: 0.35rem 0.6rem;
      box-shadow: #ccc 0.02rem 0.02rem 0.5rem 0.02rem;
      min-height: 1.89rem;
      .center-top {
        margin-bottom: 0.31rem;
      }
      .center-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .list-item {
          width: 3.5rem;
          // height: 4.45rem;
          flex-shrink: 0;
          background: #eee;
          border-radius: 0.04rem;
          overflow: hidden;
          margin-bottom: 0.48rem;
          .img-box {
            margin-bottom: 0.08rem;

            .img {
              // width: 2.14rem;
              height: 2.61rem;
              // background: url("~@/assets/images/pc/pc-home-icon5.png") no-repeat;
              // background-size: 100% 100%;
            }
          }
          .title {
            font-size: 0.18rem;
            padding: 0 0.26rem;
            margin-bottom: 0.08rem;
          }
          .integral {
            font-size: 0.18rem;
            margin-bottom: 0.08rem;
            padding: 0 0.26rem;
          }
          .total-integral {
            font-size: 0.2rem;
            color: rgba(104, 104, 104, 1);
            margin-bottom: 0.06rem;
            padding-right: 0.26rem;
          }
          .num-box {
            width: 1.02rem;
            height: 0.26rem;
            @include pcurl("pc-home-icon24.png");

            .btn-down {
              width: 0.27rem;
              height: 0.26rem;
              // background: red;
              cursor: pointer;
            }

            .btn-nums {
              flex: 1;
              height: 0.26rem;
            }

            .btn-up {
              width: 0.27rem;
              height: 0.26rem;
              // background: green;
              cursor: pointer;
            }
          }
          .btn {
            width: 100%;
            height: 0.45rem;
            background: rgba(0, 118, 255, 1);
            font-size: 0.22rem;
            font-weight: 900;
            color: #fff;
          }
        }
      }
      .center-main::after {
        content: "";
        width: 3.5rem;
      }
      .mb-center-main {
        .list-item {
          width: 7.9877rem;
          flex-shrink: 0;
          background: #eee;
          border-radius: 0.2rem;
          overflow: hidden;
          margin-bottom: 0.6144rem;
          .img-box {
            margin-bottom: 0.2048rem;

            .img {
              height: 6.554rem;
            }
          }
          .title {
            font-size: 0.6144rem;
            padding: 0 0.4608rem;
            margin-bottom: 0.4024rem;
          }
          .integral {
            font-size: 0.512rem;
            margin-bottom: 0.4096rem;
            padding: 0 0.2rem;
          }
          .total-integral {
            font-size: 0.6rem;
            color: rgba(104, 104, 104, 1);
            margin-bottom: 0.3rem;
            padding-right: 0.2rem;
          }
          .num-box {
            width: 3.9104rem;
            height: 1.0241rem;
            @include pcurl("pc-home-icon24.png");
            .btn-down {
              width: 1.0241rem;
              height: 1.0241rem;
              // background: red;
              cursor: pointer;
            }
            .btn-nums {
              flex: 1;
              height: 1.0241rem;
              font-size: 0.6144rem;
            }
            .btn-up {
              width: 1.0241rem;
              height: 1.0241rem;
              // background: green;
              cursor: pointer;
            }
          }
          .btn {
            width: 100%;
            height: 1.2801rem;
            background: rgba(0, 118, 255, 1);
            font-size: 0.6144rem;
            font-weight: 900;
            color: #fff;
          }
        }
      }
    }
    .mb-content-center {
      padding: 0;
      box-shadow: unset;
      // min-height: 1.89rem;
    }
  }
  .mb-inte-mall-content {
    .content-top {
      margin-bottom: 0.6144rem;
    }
    .content-center {
      .center-top {
        margin-bottom: 0.6144rem;
      }
    }
  }
}
.mb-view-integral-mall {
  padding: 0.6144rem 1.0241rem;
}
</style>
